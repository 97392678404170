import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Grid, Form, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { css } from 'glamor';
import Select from 'react-select';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
  }),
  form: css({
    '& textarea.form-control': {
      height: '100px',
    },
  }),
};

class GeneralDashboardSearchCriteriaPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchCriteria: {},
    };
  }

  componentDidMount() {
    this.onClear();
  }

  onClear() {
    this.setState({
      searchCriteria: {
        product: '',
        producer: '',
        manufacturer: '',
        packager: '',
        responsible: '',
        rawMats: '',
        CompanyId: '',
        action: '',
        state: '',
        activity: '*',
        isNotified: '',
        salesRep: '',
        expert: '',
        accountManager: '',
      },
    });
  }

  loadRawMats(event) {
    // alert('load rawmats ' + event.target.value)
    if (event.target.value.length > 0) {
      this.setState({ rawMat: event.target.value });
    }
  }

  render() {
    const { onSearch, companyList, rawMatList, salesRepList, expertList, accountManagerList } = this.props;
    return (
      <div className={styles.form}>
        <Form onSubmit={() => alert('test')}>
          <FormGroup controlId="Product_Filter">
            <Grid fluid>
              <Row>
                <Col sm={2}>
                  <ControlLabel>
                    <Translate value="generalDashboardSearchCriteria.product" />
                  </ControlLabel>
                  <FormControl
                    type="text"
                    bsSize="small"
                    value={this.state.searchCriteria.product}
                    onChange={event => {
                      this.setState({
                        searchCriteria: {
                          ...this.state.searchCriteria,
                          product: event.target.value,
                        },
                      });
                    }}
                    placeholder="Filter by Product..."
                  />
                </Col>
                <Col sm={2}>
                  <ControlLabel>
                    <Translate value="generalDashboardSearchCriteria.producer" />
                  </ControlLabel>
                  <Select
                    options={companyList}
                    labelKey="ShortName"
                    valueKey="CompanyId"
                    value={this.state.searchCriteria.CompanyId}
                    onChange={value => {
                      this.setState({
                        searchCriteria: {
                          ...this.state.searchCriteria,
                          CompanyId: value ? value.CompanyId : null,
                        },
                      });
                    }}
                    clearable
                  />
                </Col>
                <Col sm={2}>
                  <ControlLabel>
                    <Translate value="generalDashboardSearchCriteria.manufacturer" />
                  </ControlLabel>
                  <Select
                    options={companyList}
                    labelKey="ShortName"
                    valueKey="CompanyId"
                    value={this.state.searchCriteria.manufacturer}
                    onChange={value => {
                      this.setState({
                        searchCriteria: {
                          ...this.state.searchCriteria,
                          manufacturer: value ? value.CompanyId : null,
                        },
                      });
                    }}
                    clearable
                  />
                </Col>
                <Col sm={2}>
                  <ControlLabel>
                    <Translate value="generalDashboardSearchCriteria.packager" />
                  </ControlLabel>
                  <Select
                    options={companyList}
                    labelKey="ShortName"
                    valueKey="CompanyId"
                    value={this.state.searchCriteria.packager}
                    onChange={value => {
                      this.setState({
                        searchCriteria: {
                          ...this.state.searchCriteria,
                          packager: value ? value.CompanyId : null,
                        },
                      });
                    }}
                    clearable
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <ControlLabel>
                    <Translate value="generalDashboardSearchCriteria.rawMats" />
                  </ControlLabel>
                  <Select
                    options={rawMatList}
                    labelKey="Name"
                    closeOnSelect={false}
                    valueKey="Name"
                    simpleValue
                    value={this.state.searchCriteria.rawMats}
                    multi
                    onChange={e => {
                      this.loadRawMats(e.target.value);
                    }}
                  />
                </Col>
                <Col sm={2}>
                  <ControlLabel>
                    <Translate value="generalDashboardSearchCriteria.responsible" />
                  </ControlLabel>
                  <Select
                    options={companyList}
                    labelKey="ShortName"
                    valueKey="CompanyId"
                    value={this.state.searchCriteria.responsible}
                    onChange={value => {
                      this.setState({
                        searchCriteria: {
                          ...this.state.searchCriteria,
                          responsible: value ? value.CompanyId : null,
                        },
                      });
                    }}
                    clearable
                  />
                </Col>
                <Col sm={1}>
                  <ControlLabel>
                    <Translate value="generalDashboardSearchCriteria.active" />
                  </ControlLabel>
                  <Select
                    options={[{ code: 'yes' }, { code: 'no' }, { code: '*' }]}
                    labelKey="code"
                    valueKey="code"
                    value={this.state.searchCriteria.activity}
                    onChange={value => {
                      this.setState({
                        searchCriteria: {
                          ...this.state.searchCriteria,
                          activity: value ? value.code : '*',
                        },
                      });
                    }}
                    clearable
                  />
                </Col>
                <Col sm={1}>
                  <ControlLabel>
                    <Translate value="generalDashboardSearchCriteria.notified" />
                  </ControlLabel>
                  <Select
                    options={[{ code: 'yes' }, { code: 'no' }, { code: '*' }]}
                    labelKey="code"
                    valueKey="code"
                    value={this.state.searchCriteria.isNotified}
                    onChange={value => {
                      this.setState({
                        searchCriteria: {
                          ...this.state.searchCriteria,
                          isNotified: value ? value.code : '*',
                        },
                      });
                    }}
                    clearable
                  />
                </Col>
                <Col sm={1}>
                  <ControlLabel>
                    <Translate value="generalDashboardSearchCriteria.salesRep" />
                  </ControlLabel>
                  <Select
                    options={salesRepList}
                    labelKey="LoginName"
                    valueKey="LoginName"
                    value={this.state.searchCriteria.salesRep}
                    onChange={value => {
                      this.setState({
                        searchCriteria: {
                          ...this.state.searchCriteria,
                          salesRep: value ? value.LoginName : null,
                        },
                      });
                    }}
                    clearable
                  />
                </Col>
                <Col sm={1}>
                  <ControlLabel>
                    <Translate value="generalDashboardSearchCriteria.expert" />
                  </ControlLabel>
                  <Select
                    options={expertList}
                    labelKey="LoginName"
                    valueKey="LoginName"
                    value={this.state.searchCriteria.expert}
                    onChange={value => {
                      this.setState({
                        searchCriteria: {
                          ...this.state.searchCriteria,
                          expert: value ? value.LoginName : null,
                        },
                      });
                    }}
                    clearable
                  />
                </Col>
                <Col sm={1}>
                  <ControlLabel>
                    <Translate value="generalDashboardSearchCriteria.accountMgr" />
                  </ControlLabel>
                  <Select
                    options={accountManagerList}
                    labelKey="LoginName"
                    valueKey="LoginName"
                    value={this.state.searchCriteria.accountManager}
                    onChange={value => {
                      this.setState({
                        searchCriteria: {
                          ...this.state.searchCriteria,
                          accountManager: value ? value.LoginName : null,
                        },
                      });
                    }}
                    clearable
                  />
                </Col>
              </Row>
              <div>&nbsp;</div>
              <Row>
                <Col sm={1}>
                  <Button bsStyle="default" bsSize="small" onClick={this.onClear}>
                    <Translate value="generalDashboard.clearCriteria" />
                    &nbsp;
                    <i className="fa fa-times" aria-hidden="true" />
                  </Button>
                  &nbsp;
                  <Button bsStyle="success" bsSize="small" onClick={() => onSearch(this.state.searchCriteria)}>
                    <Translate value="generalDashboard.search" />
                    &nbsp;
                    <i className="fa fa-search" aria-hidden="true" />
                  </Button>
                </Col>
              </Row>
            </Grid>
          </FormGroup>
        </Form>
        <br/>
      </div>
    );
  }
}

GeneralDashboardSearchCriteriaPanel.defaultProps = {
  companyList: [],
  actionList: [],
  stateList: [],
  salesRepList: [],
  expertList: [],
  accountManagerList: [],
  rawMatList: [],
};

GeneralDashboardSearchCriteriaPanel.propTypes = {
  onSearch: PropTypes.func.isRequired,
  companyList: PropTypes.array.isRequired, //eslint-disable-line
  actionList: PropTypes.array.isRequired, //eslint-disable-line
  stateList: PropTypes.array.isRequired, //eslint-disable-line
  salesRepList: PropTypes.array.isRequired, //eslint-disable-line
  expertList: PropTypes.array.isRequired, //eslint-disable-line
  accountManagerList: PropTypes.array.isRequired, //eslint-disable-line
  rawMatList: PropTypes.array.isRequired, //eslint-disable-line
};

export default GeneralDashboardSearchCriteriaPanel;
