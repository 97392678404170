import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-redux-i18n';
import LoadingButton from '../LoadingButton';

const EditButton = props => {
  const { onClick, disabled, isLoading } = props;
  return (
    <LoadingButton
      bsStyle="primary"
      bsSize="xsmall"
      type="button"
      onClick={onClick}
      disabled={disabled}
      isLoading={isLoading}>
      <i className="fa fa-pen" />
      {/* &nbsp;<Translate value="general.edit" /> */}
    </LoadingButton>
  );
};

EditButton.defaultProps = {
  disabled: false,
  isLoading: false,
};

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default EditButton;
