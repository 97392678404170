import PropTypes from 'prop-types';
import Baby from 'babyparse';
import React, {PureComponent} from 'react';
import {css} from 'glamor';
import {AgGridReact} from 'ag-grid-react';
import {Button, Col, FormControl, FormGroup, InputGroup, Row} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import {Translate} from 'react-redux-i18n';
import Loader from 'react-loader-advanced';
import AutoHeight from '../AutoHeight';

const XLSX = require('xlsx');
const FileSaver = require('file-saver');

class GeneralGrid extends PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    columns: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    onSelected: PropTypes.func,
    onSelectedData: PropTypes.func,
    onRefresh: PropTypes.func,
    isLoading: PropTypes.bool,
    rowDoubleClicked: PropTypes.func,
    selectedId: PropTypes.string,
    keyName: PropTypes.string.isRequired,
    onAdd: PropTypes.func, // onInsertRow
    cellValueChanged: PropTypes.func,
    showFilterRow: PropTypes.bool,
    showAddButton: PropTypes.bool,
    showLoadingIcon: PropTypes.bool,
    height: PropTypes.string,
    rowSelection: PropTypes.string,
    rowBuffer: PropTypes.number,
    selectionChanged: PropTypes.func,
    showExpandCollapseButtons: PropTypes.bool,
    whenGridReady: PropTypes.func,
    addDisabled: PropTypes.bool,
    showRefreshButton: PropTypes.bool,
    onExtraButtonClick: PropTypes.func,
    extraButtonLabel: PropTypes.string,
    extraButtonToolTip: PropTypes.string,
    extraButtonClass: PropTypes.string,
  };

  static defaultProps = {
    rowSelection: 'single',
    showFilterRow: true,
    showLoadingIcon: false,
    isLoading: false,
    selectedId: undefined,
    showAddButton: false,
    height: '',
    rowBuffer: 10,
    selectionChanged: undefined,
    showExpandCollapseButtons: false,
    addDisabled: false,
    showRefreshButton: true,
    extraButtonLabel: 'Edit selected',
    extraButtonClass: 'fa fa-edit',
    extraButtonToolTip: 'selectionGrid.editSelected',
    whenGridReady: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      grid: null,
      rowCount: 0,
    };
    this.rowChanged = this.rowChanged.bind(this);
    this.setFilterText = this.setFilterText.bind(this);
    this.rowClicked = this.rowClicked.bind(this);
    this.gridReady = this.gridReady.bind(this);
    this.cellValueChanged = this.cellValueChanged.bind(this);
    this.rowDoubleClicked = this.rowDoubleClicked.bind(this);
    this.onModelUpdated = this.onModelUpdated.bind(this);
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.selectionChanged = this.selectionChanged.bind(this);
    this.componentStateChanged = this.componentStateChanged.bind(this);
    this.setSelectedRow = this.setSelectedRow.bind(this);
  }

  onExtraButtonClick() {
    if (this.grid) {
      if (this.grid.api.getSelectedRows().length === 0) this.grid.api.selectAllFiltered();

      const rows = this.grid.api.getSelectedRows();
      this.props.onExtraButtonClick(rows);
      this.grid.api.deselectAll();
    }
  }

  export2Excel() {
    if (this.grid) {
      this.grid.api.selectAllFiltered();
      this.download2Excel(this.parseCsv(this.grid.api.getDataAsCsv()));
      this.grid.api.deselectAll();
    }
  }

  parseCsv(csvString) {
    const json = Baby.parse(csvString, { header: true, dynamicTyping: true, });
    return XLSX.utils.json_to_sheet(json.data);
  }

  download2Excel(sheet) {
    const wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' };

    const wb = { SheetNames: [], Sheets: {} };
    wb.SheetNames.push('sheet');
    wb.Sheets.sheet = sheet;
    const wbout = XLSX.write(wb, wopts);

    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }

    FileSaver.saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), 'CalmosDownload.xlsx');
  }

  setSelectedRow(selectedId) {
    if (this.grid && selectedId !== undefined) {
      // check the id has a value
      if (selectedId !== '' && selectedId !== '-1') {
        const rowsToDisplay = this.grid.api.getModel().rowsToDisplay;
        if (rowsToDisplay) {
          // check if node to select is in the visible list. If not, look at the entire list (less efficient)
          const renderedNodes = this.grid.api.getRenderedNodes();
          const nodeToselect = this.grid.api.getRowNode(selectedId);
          if (nodeToselect) {
            this.selectAndEnsureNodeIsVisible(rowsToDisplay, renderedNodes, nodeToselect);
          }
        }
      } else if (this.grid) {
        this.grid.api.deselectAll();
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedId: newSelectedId } = nextProps;
    this.setSelectedRow(newSelectedId);
  }

  componentDidUpdate() {
    if (this.grid) {
      this.grid.api.sizeColumnsToFit();
    }
  }

  onModelUpdated() {
    if (this.grid) {
      this.grid.api.sizeColumnsToFit();
    }
  }

  setFilterText(filter) {
    this.setState({
      filter,
    });
    if (this.grid) {
      this.grid.api.setQuickFilter(filter);
    }
  }

  getRowNodeId(nodeData) {
    const { keyName } = this.props;
    return keyName ? nodeData[this.props.keyName] : undefined;
  }

  gridReady(g) {
    this.grid = g;
    this.setState({ grid: g });
    if (this.props.whenGridReady) {
      this.props.whenGridReady(g);
    }
    this.grid.api.sizeColumnsToFit();
    const { selectedId } = this.props;
    this.setSelectedRow(selectedId);
  }

  cellValueChanged(params) {
    if (this.props.cellValueChanged) {
      this.props.cellValueChanged(params.data);
    }
  }

  rowChanged() {
    if (this.grid) {
      this.grid.api.sizeColumnsToFit();
    }
  }

  rowClicked(e) {
    const { keyName } = this.props;
    if (this.props.onSelected) {
      this.props.onSelected(e.data[keyName]);
    }
    if (this.props.onSelectedData) {
      this.props.onSelectedData(e.data);
    }
  }

  rowDoubleClicked(e) {
    if (this.props.rowDoubleClicked) {
      this.props.rowDoubleClicked(e.data);
    }
  }

  handleExpandButtonClick = () => {
    if (this.grid) {
      this.grid.api.expandAll();
    }
  };

  handleCollapseButtonClick = () => {
    if (this.grid) {
      this.grid.api.collapseAll();
    }
  };

  getRowCount = () => {
    if (this.grid) {
      return this.grid.api.getDisplayedRowCount();
    }
    return 0;
  };

  selectionChanged() {
    if (this.props.selectionChanged) {
      const selectedNode = this.grid.api.getSelectedRows()[0];
      if (selectedNode) {
        const selectedId = this.getRowNodeId(selectedNode);
        this.props.selectionChanged(selectedId);
      }
    }
  }

  selectAndEnsureNodeIsVisible = (rowsToDisplay, renderedNodes, nodeToSelect) => {
    const { rowBuffer } = this.props;
    nodeToSelect.setSelected(true, true);
    if (!renderedNodes.slice(0, renderedNodes.length - (rowBuffer + 1)).includes(nodeToSelect)) {
      const lastNode = rowsToDisplay[rowsToDisplay.length - 1];
      if (lastNode) {
        this.grid.api.ensureIndexVisible(lastNode.rowIndex); // Allows to display the selectedNode at the top instead of the bottom of the grid.
        this.grid.api.ensureIndexVisible(nodeToSelect.rowIndex);
      }
    }
  };

  componentStateChanged() {
    if (this.grid) {
      this.setState({ rowCount: this.grid.api.getDisplayedRowCount() });
    }
  };

  render() {
    const loading = '<span style="padding: 10px;"><i class="fa fa-refresh fa-4x fa-spin"/></span>';
    const {
      data,
      isLoading,
      columns,
      onRefresh,
      showFilterRow,
      showAddButton,
      showLoadingIcon,
      onAdd,
      rowSelection,
      showRefreshButton,
      height,
      rowBuffer,
      showExpandCollapseButtons,
      addDisabled,
      onExtraButtonClick,
      extraButtonLabel,
      extraButtonToolTip,
      extraButtonClass,
      defaultColDef,
    } = this.props;

    const finalDefaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      ...defaultColDef,
    };

    

    const grid = (
      <AgGridReact
        rowData={data}
        onRowClicked={this.rowClicked}
        onRowDoubleClicked={this.rowDoubleClicked}
        columnDefs={columns}
        onGridReady={this.gridReady}
        onComponentStateChanged={this.componentStateChanged}
        onModelUpdated={this.onModelUpdated}
        onCellValueChanged={this.cellValueChanged}
        onGridSizeChanged={this.rowChanged}
        rowSelection={rowSelection}
        suppressCellSelection
        suppressNoRowsOverlay
        overlayLoadingTemplate={loading}
        suppressScrollOnNewData
        getRowNodeId={this.getRowNodeId}
        rowBuffer={rowBuffer}
        onSelectionChanged={this.selectionChanged}
        deltaRowDataMode
        {...this.props}
        defaultColDef={finalDefaultColDef}
      />
    );


    return (
      <div className="ag-blue">
        {showFilterRow ? (
          <Row>
            <Col sm={10}>
              <FormGroup>
                <InputGroup>
                  <FormControl
                    type="text"
                    value={this.state.filter}
                    onChange={e => this.setFilterText(e.target.value)}
                    placeholder="Filter..."
                  />
                  <InputGroup.Button>
                    {showAddButton ? (
                      <ReactTooltip id="addTip" place="bottom" type="dark" effect="solid">
                        <Translate value="selectionGrid.add" />
                      </ReactTooltip>
                    ) : null}
                    {showAddButton ? (
                      <Button onClick={onAdd} bsStyle="default" data-for="addTip" disabled={addDisabled} data-tip>
                        <i className="fa fa-plus" />
                      </Button>
                    ) : null}
                    <Button
                      onClick={() => this.export2Excel()}
                      bsStyle="success"
                      data-for="downloadTip"
                      data-tip
                      disabled={this.getRowCount() === 0}
                    >
                      <i className="fa fa-file-excel" />
                    </Button>
                    <ReactTooltip id="downloadTip" place="bottom" type="dark" effect="solid">
                      <Translate value="selectionGrid.downloadToExcel" />
                    </ReactTooltip>
                    <ReactTooltip id="refreshTip" place="bottom" type="dark" effect="solid">
                      <Translate value="selectionGrid.refreshData" />
                    </ReactTooltip>
                    {showRefreshButton && (
                      <Button onClick={onRefresh} bsStyle="primary" data-for="refreshTip" data-tip>
                        <i className="fa fa-sync-alt" />
                      </Button>
                    )}
                    <ReactTooltip id="filterTip" place="bottom" type="dark" effect="solid">
                      <Translate value="selectionGrid.clearFilter" />
                    </ReactTooltip>
                    <Button
                      onClick={() => {
                        this.setFilterText('');
                        this.grid.api.setFilterModel(null);
                      }}
                      bsStyle="danger"
                      data-for="filterTip"
                      data-tip
                    >
                      <i className="fa fa-times" />
                    </Button>
                    {showLoadingIcon ? (
                      <Button bsStyle="info" disabled>
                        <i className="fa fa-spinner fa-spin" />
                      </Button>
                    ) : null}
                    {onExtraButtonClick ? (
                      <>
                        {extraButtonToolTip && (
                          <ReactTooltip id="extraTip" place="bottom" type="dark" effect="solid">
                            <Translate value={extraButtonToolTip} />
                          </ReactTooltip>
                        )}
                        {/* <Button onClick={() => this.onExtraButtonClick()} bsStyle="light" data-for="extraTip" data-tip> */}
                        <Button onClick={() => this.onExtraButtonClick()} bsStyle="default" data-for="extraTip" data-tip>
                          <i style={{ marginRight: '5px' }} className={extraButtonClass} />{extraButtonLabel}
                        </Button>
                      </>) : null}
                  </InputGroup.Button>
                </InputGroup>

              </FormGroup>
            </Col>
            <Col sm={2} style={{ fontSize: '10px' }}>
              <div>Row count</div>
              <div>{this.state.rowCount}</div>
            </Col>
          </Row>
        ) : null}
        {showExpandCollapseButtons && (
          <div
            className={css({
              '& a:hover': {
                cursor: 'pointer',
              },
            })}
          >
            <a onClick={this.handleExpandButtonClick} role="button" tabIndex={0}>
              <Translate value="selectionGrid.expandAll" />
            </a>{' '}
            /{' '}
            <a onClick={this.handleCollapseButtonClick} role="button" tabIndex={0}>
              <Translate value="selectionGrid.collapseAll" />
            </a>
          </div>
        )}
        <Loader
          show={isLoading}
          contentBlur={1}
          message={
            <span>
              <i className="fa fa-refresh fa-spin fa-4x" />
            </span>
          }
        >
        {height && <div style={{ height: `${height}` }}>{grid}</div>}
        {!height && <AutoHeight externalUsedHeight={138}>{grid}</AutoHeight>}
       </Loader>
      </div>
    );
  }
}

export default GeneralGrid;
