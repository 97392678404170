import { LOCATION_CHANGE, replace } from 'react-router-redux';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import companyApi from '../../api/companyApi';
import companyDistributionChannelApi from '../../api/companyDistributionChannelApi';
import companyDistributorApi from '../../api/companyDistributorApi';
import formNames from '../../constants/formNames';
import { applicationActions } from '../application';
import { createSaveForm, createSaveFormWithValidation } from '../form/utils';
// import { callApiSaga, callApiSagaAndNormalize } from '../sagaUtils';
import {callApiSaga, callApiSagaAndNormalize} from '../sagaUtils';
import * as companyActions from './companyActions';
import { companyListSchema, companySchema } from './companySchemas';
import * as companyTypes from './companyTypes';
import { modalActions } from '../modal';
import { I18n } from 'react-redux-i18n';

export default function* root() {
  yield all([
    takeEvery(LOCATION_CHANGE, onLocationChange),
    takeLatest(companyTypes.COMPANY_LIST.REQUEST, getCompanyListSaga),
    takeLatest(companyTypes.COMPANY.REQUEST, getCompanySaga),
    takeLatest(companyTypes.COMPANY_SUBSCRIPTIONS_LIST.REQUEST, getCompanySubscriptionsSaga),
    takeLatest(companyTypes.COMPANY_PRODUCT_LIST.REQUEST, getCompanyProductListSaga),
    takeLatest(companyTypes.COMPANY_PRODUCT_LIST_BY_RAW_MATS.REQUEST, exportCompanyProductsByRawMatsSaga),
    takeLatest(companyTypes.COMPANY_RAW_MAT_LIST.REQUEST, getCompanyRawMatListSaga),
    takeLatest(companyTypes.COMPANY_DISTRIBUTOR_LIST.REQUEST, getCompanyDistributorListSaga),
    takeLatest(companyTypes.COMPANY_DISTRIBUTION_CHANNEL_LIST.REQUEST, getCompanyDistributionChannelListSaga),
    takeLatest(companyTypes.COMPANY_ORDER_LIST.REQUEST, getCompanyOrderListSaga),
    takeLatest(companyTypes.COMPANY_DISTRIBUTOR_LIST.REQUEST, getCompanyDistributorListSaga),
    takeLatest(companyTypes.SAVE_COMPANY.REQUEST, saveCompanyFormSaga),
    takeLatest(companyTypes.SUBMIT_COPY_RM_FORM.REQUEST, submitCopyRMSaga),
    takeLatest(companyTypes.DELETE_COMPANY.REQUEST, deleteCompanySaga),
    takeLatest(companyTypes.ADD_DISTRIBUTOR_TO_COMPANY.REQUEST, addDistributorToCurrentCompanySaga),
    takeLatest(companyTypes.ADD_DISTRIBUTION_CHANNEL_TO_COMPANY.REQUEST, addDistributionChannelToCompanySaga),
    takeLatest(companyTypes.REMOVE_DISTRIBUTION_CHANNEL_FROM_COMPANY.REQUEST, removeDistributionChannelFromCompanySaga),
    takeLatest(companyActions.submitCompanyForm.REQUEST, submitCompanyFormSaga),
    takeLatest(companyTypes.UPDATE_COMPANY_RPSTATUS.REQUEST, updateCompanyRPStatusSaga),
  ]);
}

const submitCompanyFormSaga = createSaveFormWithValidation(companyActions.submitCompanyForm, saveCompanySaga);

const saveCompanyFormSaga = createSaveForm(formNames.company, saveCompanySaga);

function* onLocationChange(action) {
  // if (action.payload.pathname === '/companies/-1') {
  //   yield put(companyActions.newCompany());
  // }
}

function* getCompanyListSaga() {
  yield put(applicationActions.setLoading(companyTypes.COMPANY_LIST.REQUEST));
  yield callApiSagaAndNormalize(companyActions.listCompanies, companyApi.getCompanyList, companyListSchema);
  yield put(applicationActions.unsetLoading(companyTypes.COMPANY_LIST.REQUEST));
}

export function* getCompanySaga(action) {
  yield put(applicationActions.setLoading(companyTypes.COMPANY.REQUEST));
  yield callApiSagaAndNormalize(companyActions.company, companyApi.getCompany, companySchema, action.payload);
  yield put(applicationActions.unsetLoading(companyTypes.COMPANY.REQUEST));
}

function* getCompanyProductListSaga(action) {
  yield put(applicationActions.setLoading(companyTypes.COMPANY_PRODUCT_LIST.REQUEST));
  yield callApiSaga(companyActions.listCompanyProducts, companyApi.getCompanyProducts, action.payload);
  yield put(applicationActions.unsetLoading(companyTypes.COMPANY_PRODUCT_LIST.REQUEST));
}

function* getCompanySubscriptionsSaga(action) {
  yield put(applicationActions.setLoading(companyTypes.COMPANY_SUBSCRIPTIONS_LIST.REQUEST));
  yield callApiSaga(companyActions.listCompanySubscriptions, companyApi.getCompanySubscriptions, action.payload);
  yield put(applicationActions.unsetLoading(companyTypes.COMPANY_SUBSCRIPTIONS_LIST.REQUEST));
}

function* exportCompanyProductsByRawMatsSaga(action) {
  yield put(applicationActions.setLoading(companyTypes.COMPANY_PRODUCT_LIST_BY_RAW_MATS.REQUEST));
  yield callApiSaga(companyActions.exportCompanyProductsByRawMats, companyApi.exportCompanyProductsByRawMats, action.payload);
  yield put(applicationActions.unsetLoading(companyTypes.COMPANY_PRODUCT_LIST_BY_RAW_MATS.REQUEST));
}

function* getCompanyRawMatListSaga(action) {
  yield put(applicationActions.setLoading(companyTypes.COMPANY_RAW_MAT_LIST.REQUEST));
  yield callApiSaga(companyActions.listCompanyRawMats, companyApi.getCompanyRawMats, action.payload);
  yield put(applicationActions.unsetLoading(companyTypes.COMPANY_RAW_MAT_LIST.REQUEST));
}

function* getCompanyDistributorListSaga(action) {
  yield put(applicationActions.setLoading(companyTypes.COMPANY_DISTRIBUTOR_LIST.REQUEST));
  yield callApiSaga(companyActions.listCompanyDistributors, companyApi.getCompanyDistributors, action.payload);
  yield put(applicationActions.unsetLoading(companyTypes.COMPANY_DISTRIBUTOR_LIST.REQUEST));
}

function* getCompanyOrderListSaga(action) {
  yield put(applicationActions.setLoading(companyTypes.COMPANY_ORDER_LIST.REQUEST));
  yield callApiSaga(companyActions.listCompanyOrders, companyApi.getCompanyOrders, action.payload);
  yield put(applicationActions.unsetLoading(companyTypes.COMPANY_ORDER_LIST.REQUEST));
}

function* getCompanyDistributionChannelListSaga(action) {
  yield put(applicationActions.setLoading(companyTypes.COMPANY_DISTRIBUTION_CHANNEL_LIST.REQUEST));
  yield callApiSaga(
    companyActions.listCompanyDistributionChannels,
    companyApi.getCompanyDistributionChannels,
    action.payload,
  );
  yield put(applicationActions.unsetLoading(companyTypes.COMPANY_DISTRIBUTION_CHANNEL_LIST.REQUEST));
}

function* saveCompanySaga(action) {
  yield put(applicationActions.setLoading(companyTypes.SAVE_COMPANY.REQUEST));
  try {
    const id = yield callApiSaga(companyActions.saveCompany, companyApi.saveCompany, action.payload);
    if (id !== undefined) {
      const previousLocation = yield select(state => state.routing.locationBeforeTransitions);
      yield put(
        replace({
          ...previousLocation,
          query: { show_details: previousLocation.query.show_details || false },
          pathname: `/companies/${id}`,
        }),
      );
      yield put(companyActions.company.request(id));
      yield put(companyActions.listCompanies.request());
    }
  } catch (error) {
    throw error;
  } finally {
    yield put(applicationActions.unsetLoading(companyTypes.SAVE_COMPANY.REQUEST));
  }
}

function* deleteCompanySaga(action) {
  yield put(applicationActions.setLoading(companyTypes.DELETE_COMPANY.REQUEST));
  yield callApiSaga(companyActions.deleteCompany, companyApi.deleteCompany, action.payload);
  yield put(replace('/companies/'));
  yield put(companyActions.listCompanies.request());
  yield put(applicationActions.unsetLoading(companyTypes.DELETE_COMPANY.REQUEST));
}

function* addDistributorToCurrentCompanySaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { CompanyId } = payload;
  yield callApiSaga(companyActions.addDistributorToCompany, companyDistributorApi.saveCompanyDistributor, payload);
  yield put(companyActions.listCompanyDistributors.request(CompanyId));
  yield put(applicationActions.unsetLoading(type));
}

function* addDistributionChannelToCompanySaga({ payload }) {
  yield put(applicationActions.setLoading(companyTypes.ADD_DISTRIBUTION_CHANNEL_TO_COMPANY.REQUEST));
  const { CompanyId } = payload;
  yield callApiSaga(
    companyActions.addDistributionChannelToCompany,
    companyDistributionChannelApi.saveCompanyDistributionChannel,
    payload,
  );
  yield put(companyActions.listCompanyDistributionChannels.request(CompanyId));
  yield put(applicationActions.unsetLoading(companyTypes.ADD_DISTRIBUTION_CHANNEL_TO_COMPANY.REQUEST));
}

function* removeDistributionChannelFromCompanySaga(action) {
  yield put(applicationActions.setLoading(companyTypes.REMOVE_DISTRIBUTION_CHANNEL_FROM_COMPANY.REQUEST));
  const {
    payload: { CompanyId, DistributionChannelCode },
  } = action;
  yield callApiSaga(
    companyActions.removeDistributionChannelFromCompany,
    companyApi.removeDistributionChannelFromCompany,
    CompanyId,
    DistributionChannelCode,
  );
  yield put(companyActions.listCompanyDistributionChannels.request(CompanyId));
  yield put(applicationActions.unsetLoading(companyTypes.REMOVE_DISTRIBUTION_CHANNEL_FROM_COMPANY.REQUEST));
}

function* submitCopyRMSaga(action) {
  yield put(applicationActions.setLoading(companyTypes.SUBMIT_COPY_RM_FORM.REQUEST));
  yield put(modalActions.hideModal());
  try {
    yield callApiSaga(companyActions.submitCopyRmAction, companyApi.copyRmToCompany, action.payload);
    yield put(applicationActions.showNotification(I18n.t('modalCopyRawMat.successMessage'), 'success'));
  } catch (error) {
    yield put(applicationActions.showNotification(I18n.t('modalCopyRawMat.errorMessage'), 'error'));
  } finally {
    yield put(applicationActions.unsetLoading(companyTypes.SUBMIT_COPY_RM_FORM.REQUEST));
  }
}

// const updateCompanyRPStatusSaga = createFetchSaga(
//   function* updateCompanyRPStatusSaga({payload, type}) {
//     console.log(payload);
//     const id = yield callApiSaga(companyActions.updateRPStatus, companyApi.updateRPStatus, payload);
//     return id;
//   },
//   {
//     * onSuccess(id, payload) {
//       console.log('success')
//       yield put(companyActions.updateRPStatus.success(id, payload));
//       // const isModalShown = yield select(modalSelectors.isModalShown);
//       // if (isModalShown) {
//       //   yield put(modalActions.hideModal());
//       // }
//       if (id !== undefined) {
//         yield put(companyActions.company.request(id));
//         // yield put(companyActionActions.listCompanyActions.request(id));
//       }
//     },
//     onFailure: (error, payload) => {
//       console.log('error');
//       put(companyActions.updateRPStatus.failure(error, payload));
//     },
//   },
// );

function* updateCompanyRPStatusSaga({ payload }) {
  console.log(payload);
  try {
    const id = yield callApiSaga(companyActions.updateRPStatusAction2, companyApi.updateRPStatus, payload);
    console.log('success')
    yield put(companyActions.updateRPStatusAction2.success(id, payload));
    // if (id !== undefined) {
    //   yield put(companyActions.company.request(id));
    //   // yield put(companyActionActions.listCompanyActions.request(id));
    // }
    if (id !== undefined) {
      const previousLocation = yield select(state => state.routing.locationBeforeTransitions);
      yield put(
        replace({
          ...previousLocation,
          query: { show_details: previousLocation.query.show_details || false },
          pathname: `/companies/${id}`,
        }),
      );
      yield put(companyActions.company.request(id));
      yield put(companyActions.listCompanies.request());
    }
  } catch (error) {
    console.log('error');
    put(companyActions.updateRPStatusAction2.failure(error, payload));
  }
}
