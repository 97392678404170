import PropTypes from 'prop-types';
import React from 'react';
import { Col, Tabs, Tab } from 'react-bootstrap';
import { css } from 'glamor';
import { I18n } from 'react-redux-i18n';
import ProductOrdersContainer from './ProductOrders';
import ProductTasksContainer from './ProductTasks';
import ProductPhraseFormContainer from './ProductPhraseForm';
// import CosmosCompositionContainer from './CosmosComposition';
// import CertCompositionContainer from './CertComposition';
import CompositionContainer from './Composition';
import InciListContainer from './InciList';
import MosCalculationContainer from './MosCalculation';
import ProductCategoriesContainer from './ProductCategories';
import ProductDetailsContainer from './_ProductDetailsContainer';
import FormulaReviewContainer from './FormulaReview';
import LabelReviewContainer from './LabelReview';
import NotificationContainer from './Notifications';
import ProductDocumentsContainer from './ProductDocuments';
import Shades from './Shades';
import DraftLabel from './DraftLabel';
import ProductImpactAssessmentsContainer from './ProductImpactAssessments';
import CheckListContainer from './CheckList/_CheckListContainer';
import ProductPhraseTRContainer from "./ProductPhraseTranslation/_ProductPhraseTRContainer";

const styles = {
  detailsContainer: css({
    '& form': {
      height: '100%',
    },
    '& .tab-pane': {
      paddingTop: '15px',
    },
  }),
  buttonPanel: css({
    textAlign: 'center',
  }),
};

const ProductDetails = props => {
  const { nbProdigumProductProportionChanged } = props;
  const productId = Number(props.params.id);
  const duplicateId = (props.location.query && Number(props.location.query.duplicateFrom)) || null;
  const cosmosCompositionTitle = `${I18n.t('product.cosmosCompositionTab')} ${
    nbProdigumProductProportionChanged > 0 ? `(${nbProdigumProductProportionChanged})` : ''
  }`;
  const defaultActiveKey = props.location.query.openTab?parseInt(props.location.query.openTab):1
  const addRawMat = props.location.query.addRawMat?parseInt(props.location.query.addRawMat):-1
  return (
    <div className={styles.detailsContainer}>
      <ProductDetailsContainer productId={productId} duplicateId={duplicateId} {...props} />
      <Col sm={12}>
        <Tabs id="ProductTabs" mountOnEnter unmountOnExit defaultActiveKey={defaultActiveKey}>
          <Tab eventKey={1} title={I18n.t('product.orderTab')}>
            <ProductOrdersContainer productId={productId} />
          </Tab>
          <Tab eventKey={100} title={I18n.t('product.taskTab')}>
            <ProductTasksContainer productId={productId} />
          </Tab>
          {/* <Tab eventKey={2} title={cosmosCompositionTitle}>
            <CosmosCompositionContainer productId={productId} />
          </Tab>
          <Tab eventKey={200} title={I18n.t('product.certCompositionTab')}>
            <CertCompositionContainer productId={productId} />
          </Tab> */}
          <Tab eventKey={3} title={I18n.t('product.compositionTab')}>
            <CompositionContainer productId={productId} rawMatId={addRawMat} location={props.location} />
          </Tab>
          <Tab eventKey={4} title={I18n.t('product.shades')}>
            <Shades productId={productId} />
          </Tab>
          <Tab eventKey={65} title={I18n.t('product.catTab')}>
            <ProductCategoriesContainer productId={productId} />
          </Tab>
          <Tab eventKey={5} title={I18n.t('product.InciTab')}>
            <InciListContainer productId={productId} />
          </Tab>
          <Tab eventKey={6} title={I18n.t('product.MoSTab')}>
            <MosCalculationContainer productId={productId} />
          </Tab>
          <Tab eventKey={8} title={I18n.t('product.formulaReview')}>
            <FormulaReviewContainer productId={productId} />
          </Tab>
          <Tab eventKey={9} title={I18n.t('product.checkList')}>
            <CheckListContainer productId={productId} />
          </Tab>
          <Tab eventKey={10} title={I18n.t('product.cpsraTab')}>
            <ProductPhraseFormContainer productId={productId} CpsrTypeCode="CPSRA" />
          </Tab>
          <Tab eventKey={11} title={I18n.t('product.cpsraTranslationTab')}>
            <ProductPhraseTRContainer productId={productId} CpsrTypeCode="CPSRA" />
          </Tab>
          <Tab eventKey={12} title={I18n.t('product.cpsrbTab')}>
            <ProductPhraseFormContainer productId={productId} CpsrTypeCode="CPSRB" />
          </Tab>
          <Tab eventKey={13} title={I18n.t('product.cpsrbTranslationTab')}>
            <ProductPhraseTRContainer productId={productId} CpsrTypeCode="CPSRB" />
          </Tab>
          <Tab eventKey={14} title={I18n.t('product.labelReview')}>
            <LabelReviewContainer productId={productId} />
          </Tab>
          <Tab eventKey={15} title={I18n.t('product.draftLabel')}>
            <DraftLabel productId={productId} />
          </Tab>
          <Tab eventKey={18} title={I18n.t('product.notifications')}>
            <NotificationContainer productId={productId} />
          </Tab>
          <Tab eventKey={16} title={I18n.t('product.impactAssessment')}>
            <ProductImpactAssessmentsContainer productId={productId} />
          </Tab>
          <Tab eventKey={17} title={I18n.t('product.files')}>
            <ProductDocumentsContainer productId={productId} />
          </Tab>
        </Tabs>
      </Col>
    </div>
  );
};

ProductDetails.defaultProps = {
  nbProdigumProductProportionChanged: 0,
  location: PropTypes.shape({
    query: {},
  }),
};

ProductDetails.propTypes = {
  nbProdigumProductProportionChanged: PropTypes.number,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      duplicateFrom: PropTypes.string,
    }),
  }),
};

export default ProductDetails;
