import { createAction } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';
import { createRequestActions } from '../utils';
import * as companyTypes from './companyTypes';
import workflowTypes from '../../constants/workflowTypes';

export const submitCompanyForm = createFormAction(companyTypes.SUBMIT_COMPANY_FORM);

export const listCompanies = createRequestActions(companyTypes.COMPANY_LIST);
export const company = createRequestActions(companyTypes.COMPANY);
export const listCompanyProducts = createRequestActions(companyTypes.COMPANY_PRODUCT_LIST);
export const listCompanyRawMats = createRequestActions(companyTypes.COMPANY_RAW_MAT_LIST);
export const listCompanySubscriptions = createRequestActions(companyTypes.COMPANY_SUBSCRIPTIONS_LIST);
export const exportCompanyProductsByRawMats = createRequestActions(companyTypes.COMPANY_PRODUCT_LIST_BY_RAW_MATS);
export const listCompanyDistributors = createRequestActions(companyTypes.COMPANY_DISTRIBUTOR_LIST);
export const listCompanyOrders = createRequestActions(companyTypes.COMPANY_ORDER_LIST);
export const listCompanyDistributionChannels = createRequestActions(companyTypes.COMPANY_DISTRIBUTION_CHANNEL_LIST);
export const saveCompany = createRequestActions(companyTypes.SAVE_COMPANY);
export const deleteCompany = createRequestActions(companyTypes.DELETE_COMPANY);
export const addDistributorToCompany = createRequestActions(companyTypes.ADD_DISTRIBUTOR_TO_COMPANY);
export const addDistributionChannelToCompany = createRequestActions(
  companyTypes.ADD_DISTRIBUTION_CHANNEL_TO_COMPANY,
  (CompanyId, DistributionChannelCode) => ({
    CompanyId,
    DistributionChannelCode,
  }),
);
export const removeDistributionChannelFromCompany = createRequestActions(
  companyTypes.REMOVE_DISTRIBUTION_CHANNEL_FROM_COMPANY,
);
export const newCompany = createAction(companyTypes.NEW_COMPANY);

// Actions creators

export const addDistributorToCurrentCompanyAction = (CompanyId, { DistributorId }) => ({
  type: companyTypes.ADD_DISTRIBUTOR_TO_COMPANY.REQUEST,
  payload: {
    CompanyId,
    DistributorId,
  },
});

export const removeDistributionChannelFromCompanyAction = (CompanyId, { DistributionChannelCode }) => ({
  type: companyTypes.REMOVE_DISTRIBUTION_CHANNEL_FROM_COMPANY.REQUEST,
  payload: {
    CompanyId,
    DistributionChannelCode,
  },
});

export const submitCopyRmAction = createRequestActions(companyTypes.SUBMIT_COPY_RM_FORM);

export const updateRPStatusAction = ({WorkflowStatusCode, CompanyId }) => ({
  type: companyTypes.UPDATE_COMPANY_RPSTATUS.REQUEST,
  payload: {
    WorkflowStatusCode,
    CompanyId,
    WorkflowCode: workflowTypes.COMPANY_RP,
  },
});

export const updateRPStatusAction2 = createRequestActions(companyTypes.UPDATE_COMPANY_RPSTATUS2);