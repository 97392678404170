import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import DashboardsList from './DashboardsList';
import config from '../../config';

const dashboardIrame = config.baseUrl + '/../pages/Dashboard';
//import BasicDetailContainer from '../_Common/BasicDetailContainer';

const Dashboards = ({ children, isLoadingDetails, ...otherProps }) => (
  // <iframe src={dashboardIrame} 
  //         style={{ height: "100%", 
  //                 width: "100%", 
  //                 borderStyle: "none" }}>

  // </iframe>
  <Grid fluid>
    <Row>
      <Col md={12}>
        <DashboardsList {...otherProps} />
      </Col>
      {/* <Col md={9}>
        <BasicDetailContainer isLoading={isLoadingDetails}>
          {children}
        </BasicDetailContainer>
      </Col> */}
    </Row>
  </Grid>
);
Dashboards.propTypes = {
  children: PropTypes.node,
  isLoadingDetails: PropTypes.bool,
};

export default Dashboards;
