import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Grid, Row, Form } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { compose } from 'recompose';
import formNames from '../../../../constants/formNames';
import { InputField, CheckBoxField } from '../../../_Common/Forms';
import { CosAnnexeTypesDropdown } from '../../../_Common/Dropdowns';
import { cosAnnexeFormActions, cosAnnexeFormSelectors } from '../../../../redux/form/cosAnnexeForm';
import { referentialSelectors } from '../../../../redux/referentials';
import {
  requiredField,
  maxLength16,
  maxLength32,
  isNumberField,
  isInteger,
  maxLength1024,
} from '../../../../utils/fieldValidation';
import SelectField from "../../Forms/SelectField";
import regionCode from "../../../../constants/regionCode";

const CosAnnexeForm = (props) => {
  const { regions, countries, selectedRegion, change } = props;
  const finalCountryList = selectedRegion ? countries.filter(elem => elem.RegionCode === selectedRegion) : countries;

  return (
    <Form>
      <Grid fluid>
        <Row>
          <Col sm={4}>
            <Field
              name="Code"
              label={`${I18n.t('cosAnnexe.Code')} *`}
              component={InputField}
              validate={[requiredField, maxLength16]}
            />
          </Col>
          <Col sm={4}>
            <Field
              name="Name"
              label={`${I18n.t('cosAnnexe.Name')} *`}
              component={InputField}
              validate={[requiredField, maxLength32]}
            />
          </Col>
          <Col sm={4}>
            <Field
              name="Reference"
              label={`${I18n.t('cosAnnexe.Reference')} *`}
              component={InputField}
              validate={[requiredField, maxLength16]}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field
              name="RegionCode"
              label={I18n.t('cosAnnexe.Region')}
              component={SelectField}
              options={regions}
              labelField="Value"
              valueField="Key"
              matchPos="start"
              onValueChange={() => change('CountryAlpha2', null)}
            />
          </Col>
          <Col sm={6}>
            <Field
              name="CountryAlpha2"
              label={I18n.t('cosAnnexe.Country')}
              component={SelectField}
              options={finalCountryList}
              labelField="Value"
              valueField="Key"
              matchPos="start"
              disabled={!selectedRegion || selectedRegion !== regionCode.ASEAN}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Field
              name="CosRegulation"
              label={`${I18n.t('cosAnnexe.CosRegulation')}`}
              component={InputField}
              validate={[maxLength32]}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Field
              name="MaxUse"
              label={`${I18n.t('cosAnnexe.MaxUse')} *`}
              inputGroupLabel={'%'}
              component={InputField}
              validate={[requiredField, isNumberField]}
            />
          </Col>
          <Col sm={4}>
            <Field
              name="PhMin"
              label={`${I18n.t('cosAnnexe.PhMin')} *`}
              inputGroupLabel={'%'}
              component={InputField}
              validate={[requiredField, isInteger]}
            />
          </Col>
          <Col sm={4}>
            <Field
              name="PhMax"
              label={`${I18n.t('cosAnnexe.PhMax')} *`}
              inputGroupLabel={'%'}
              component={InputField}
              validate={[requiredField, isInteger]}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field name="UsedIn" label={`${I18n.t('cosAnnexe.UsedIn')}`} component={CosAnnexeTypesDropdown} />
          </Col>
          <Col sm={6}>
            <Field name="NotUsedIn" label={`${I18n.t('cosAnnexe.NotUsedIn')}`} component={CosAnnexeTypesDropdown} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Field
              name="CalculationCondition"
              label={`${I18n.t('cosAnnexe.CalculationCondition')}`}
              component={InputField}
              componentClass="textarea"
              validate={maxLength1024}
            />
          </Col>
          <Col sm={12}>
            <Field
              name="UseConditionWording"
              label={`${I18n.t('cosAnnexe.UseConditionWording')}`}
              component={InputField}
              componentClass="textarea"
              validate={maxLength1024}
            />
          </Col>
          <Col sm={12}>
            <Field
              name="Warning"
              label={`${I18n.t('cosAnnexe.Warning')}`}
              component={InputField}
              componentClass="textarea"
              validate={maxLength1024}
            />
          </Col>
          <Col sm={12}>
            <Field
              name="SccsOpinions"
              label={`${I18n.t('cosAnnexe.SccsOpinions')}`}
              component={InputField}
              componentClass="textarea"
              validate={maxLength1024}
            />
          </Col>
          <Col sm={12}>
            <Field
              name="OtherRestrictions"
              label={`${I18n.t('cosAnnexe.OtherRestrictions')}`}
              component={InputField}
              componentClass="textarea"
              validate={maxLength1024}
            />
          </Col>
          <Col sm={12}>
            <Field
              name="OtherRemarks"
              label={`${I18n.t('cosAnnexe.OtherRemarks')}`}
              component={InputField}
              componentClass="textarea"
              validate={maxLength1024}
            />
          </Col>
          <Col sm={12}>
            <Field
              name="DisplayInCheckPrint"
              label={`${I18n.t('cosAnnexe.DisplayInCheckPrint')}`}
              component={CheckBoxField}
              formAlignment={false}
            />
          </Col>
        </Row>
      </Grid>
    </Form>
  );
}

CosAnnexeForm.defaultProps = {
  initialValues: {},
};

CosAnnexeForm.propTypes = {
  initialValues: PropTypes.object, //eslint-disable-line
};

const mapStateToProps = (state, { cosAnnexeId }) => {
  const getInitialValues = cosAnnexeFormSelectors.makeGetInitialValues(state);
  const selector = formValueSelector(formNames.cosAnnexe);
  const selectedRegion = selector(state, 'RegionCode');
  return {
    initialValues: getInitialValues(cosAnnexeId),
    regions: referentialSelectors.getRegions(state),
    countries: referentialSelectors.getCountryList(state),
    selectedRegion,
  };
};

const mapDispatchToProps = (dispatch, { substanceId }) => ({
  onSubmit: cosAnnexeFormActions.submitCosAnnexeForm,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formNames.cosAnnexe,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(CosAnnexeForm);
