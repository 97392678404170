import PropTypes from 'prop-types';
import React from 'react';
import idx from 'idx';
import { Button, InputGroup } from 'react-bootstrap';
import { applicationActions } from '../../redux/application';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

const StepComponent = props => {
  const {
    options,
    valueField,
    labelField,
    valueRenderer,
    onChange,
    onCheckBeforeChange,
    value,
    disabled,
    onHistoryClick,
    nextStepDisabled,
    showNotification,
    changeStateErrorMessage,
  } = props;

  const isFirstValueSelected = idx(options, _ => _[0][valueField] === value);
  const isLastValueSelected = idx(options, _ => _[options.length - 1][valueField] === value);

  const getCurrentValue = () => {
    if (options && options.length > 0 && value) {
      return options.find(status => status[valueField] === value);
    }
    return undefined;
  };

  const getDefaultValue = () => {
    if (options && options.length > 0) {
      return options[0];
    }
    return '';
  };

  const getDisplayedValue = () => {
    const currentValue = getCurrentValue();
    let displayedValue;
    if (currentValue) {
      displayedValue = typeof valueRenderer === 'function' ? valueRenderer(currentValue) : currentValue;
    } else {
      displayedValue = getDefaultValue();
    }
    let result = displayedValue[labelField];
    return result; 
  };

  const createAdd = numberToAdd => number => number + numberToAdd;
  const addOne = createAdd(1);
  const removeOne = createAdd(-1);

  const handleChange = operation => {
    if (typeof onChange === 'function') {
      let canChange = true;

      const currentValue = getCurrentValue();
      if (currentValue) {
        const currentIndex = options.indexOf(currentValue);
        const nextValue = options[operation(currentIndex)];

        if (typeof onCheckBeforeChange === 'function') {
          canChange = onCheckBeforeChange(nextValue);
        }
        if (canChange) {
          onChange(nextValue);
        } else {
          showNotification(
            changeStateErrorMessage ? I18n.t(changeStateErrorMessage) : I18n.t('general.cannotChangeStatus'),
          );
        }
      }
    }
  };

  return (
    <InputGroup.Button>
      <Button
        bsSize="small"
        bsStyle="primary"
        onClick={() => handleChange(removeOne)}
        disabled={isFirstValueSelected || disabled}
      >
        <i className="fa fa-chevron-left" />
      </Button>
      <Button bsSize="small" bsStyle="primary">
        <span dangerouslySetInnerHTML={{__html: getDisplayedValue()}} />
      </Button>
      <Button
        bsSize="small"
        bsStyle="primary"
        onClick={() => handleChange(addOne)}
        disabled={isLastValueSelected || disabled || nextStepDisabled}
      >
        <i className="fa fa-chevron-right" />
      </Button>
      {onHistoryClick && (
        <Button bsSize="small" onClick={onHistoryClick}>
          <i className="fa fa-history fa-lg" aria-hidden="true" />
        </Button>
      )}
    </InputGroup.Button>
  );
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  showNotification: message => dispatch(applicationActions.showNotification(message, 'error')),
});

StepComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onCheckBeforeChange: PropTypes.func,
  options: PropTypes.array.isRequired, //eslint-disable-line
  labelField: PropTypes.string,
  valueField: PropTypes.string,
  valueRenderer: PropTypes.func,
  disabled: PropTypes.bool,
  nextStepDisabled: PropTypes.bool,
  onHistoryClick: PropTypes.func,
  changeStateErrorMessage: PropTypes.string,
};

StepComponent.defaultProps = {
  labelField: 'Name',
  valueField: 'Code',
  nextStepDisabled: false,
  valueRenderer: undefined,
  onChange: undefined,
  value: undefined,
  disabled: false,
  onHistoryClick: undefined,
};

export default connect(
  null,
  mapDispatchToProps,
)(StepComponent);
