import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'glamor';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import SelectionGrid from '../../_Common/Grids/SelectionGrid';
import { DateCellRenderer , LinkCellRenderer} from '../../_Common/Grids';
import { link } from 'react-dom-factories';
// const getYN = ({data, colDef}) =>
//   R.compose(
//     boolToYN,
//     R.propOr(false, colDef.field),
//   )(data);
// const boolToYN = R.ifElse(R.equals(true), R.always('yes'), R.always('no'));

const getNoLongerInMarket = ({ data, colDef }) => {
  return data.IsNoLongerOnMarket ? 'yes' : '';
};

const CompanySubscriptionsList = props => {
  const {
    currentCompanySubscriptions,
    requestingCompanySubscriptionsList,
    onRefresh,

    onRowDoubleClicked,
    workflowStatusNameById,
  } = props;
  const styles = {
    main: css({
      height: '100%',
    }),
    gridContainer: css({
      height: '100%',
    }),
  };

  const getWorkflowName = ({ data, colDef }) => R.propOr('', 'Name', workflowStatusNameById[data[colDef.field]]);

  const columns = [
    {
      headerName: 'Subscription',
      field: 'ExternalReference',
      cellRendererFramework: LinkCellRenderer,
      cellRendererParams: {
        textKey: 'SubscriptionCode',
        linkKey: 'ExternalReference',
        target: '_blank',
        downloadable: false,
        linkFunc: (data) => {return  "https://biorius.odoo.com/web#model=sale.subscription&id=" + data.ExternalReference; }
      },
      maxWidth: "120"
    },
    {
      headerName: I18n.t('subscription.code'),
      field: 'Code',
      maxWidth: "120"
    },
    {
      headerName: I18n.t('subscription.name'),
      field: 'Name'
    },
    {
      headerName: 'Quantity',
      field: 'Quantity',
      maxWidth: "100"
    }
  ];


  return (
    <div className={`${styles.main} ag-blue`}>
      <div className={styles.gridContainer}>
        <SelectionGrid
          data={currentCompanySubscriptions}
          keyName="Id"
          columns={columns}
          isLoading={requestingCompanySubscriptionsList}
          onRefresh={onRefresh}
          showAddButton={false}
          showRefreshButton={false}
        />
      </div>
    </div>
  );
};

CompanySubscriptionsList.propTypes = {
  currentCompanySubscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number,
    }),
  ).isRequired,
  requestingCompanySubscriptionsList: PropTypes.bool.isRequired,
  workflowStatusNameById: PropTypes.object.isRequired, //eslint-disable-line
};

export default CompanySubscriptionsList;
