import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'glamor';
import { Alert, Collapse } from 'react-bootstrap';
import NotificationSystem from 'react-notification-system';
import Header from './Header';
import Navigation from './Navigation';
import Footer from './Footer';
import UAParser from 'ua-parser-js';
import { connect } from 'react-redux';
import { sessionActions } from '../../redux/session';
import { userSelectors } from '../../redux/user';

const styles = {
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  navBox: css({
    paddingTop: '42px',
    display: 'flex',
    height: 'calc(100vh - 42px)',
  }),
  content: css({
    width: '100%',
    'padding-top': '10px',
  }),
  newLine: css({
    whiteSpace: 'pre-line'
  })
};

class App extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    generalError: PropTypes.string,
    showNotification: PropTypes.bool,
    notificationMessage: PropTypes.string,
    onNotificationEmited: PropTypes.func,
    applicationVersion: PropTypes.string,
    onGeneralErrorEmited: PropTypes.func.isRequired,
    notificationLevel: PropTypes.string,
    errorCode: PropTypes.string,
  };


  static defaultProps = {
    errorCode: '',
  };

  constructor(props) {
    super(props);
    this.addNotification = this.addNotification.bind(this);
    this.state= {
      user: '',
      device: '',
      cpu: '',
      cores: '',
      browser: '',
      os: '',
      ip: '',
      isp: '',
      sent: false
    }
  }


  componentWillMount() {
    var onSuccess = function(location){
      ref.setState({ isp: location.traits.isp });
      if (!ref.state.sent && ref.state.ip !== "" && ref.state.isp !== "" )
      {
        var session = {
          user: ref.props.currentUser.UserName,
          device: ref.state.device,
          cpu: ref.state.cpu,
          cores: ref.state.cores,
          os: ref.state.os,
          browser: ref.state.browser,
          ip: ref.state.ip,
          isp: ref.state.isp
        };
        var dispatch = ref.props.dispatch;
        dispatch(sessionActions.startSession.request({ session: session }));
        ref.setState({ sent: true });
      }
    };

    var onError = function(error){
      console.log(
          "Error:\n\n"
          + JSON.stringify(error, undefined, 4)
      );
      ref.setState({ isp: "N/A" });
      if (!ref.state.sent && ref.state.ip !== "" && ref.state.isp !== "" )
      {
        var session = {
          user: ref.props.currentUser.UserName,
          device: ref.state.device,
          cpu: ref.state.cpu,
          cores: ref.state.cores,
          os: ref.state.os,
          browser: ref.state.browser,
          ip: ref.state.ip,
          isp: ref.state.isp
        };
        var dispatch = ref.props.dispatch;
        dispatch(sessionActions.startSession.request({ session: session }));
        ref.setState({ sent: true });
      }
    };

    window.geoip2.city(onSuccess, onError);

    var parser = new UAParser();
    if (parser.getDevice() && parser.getDevice().name) {
        this.setState({ device: JSON.stringify(parser.getDevice()) });
    }

    this.setState({ cpu: navigator.platform + ', ' });
    if (parser.getCPU() && parser.getCPU().name) {
      this.setState({ cpu: this.state.cpu + JSON.stringify(parser.getCPU()) + ' - ' });
    }
    this.setState({ cores: (navigator.hardwareConcurrency ? navigator.hardwareConcurrency : '') });


    this.setState({ os: parser.getOS().name + ' ' + parser.getOS().version });
    this.setState({ browser: parser.getBrowser().name + ' ' + parser.getBrowser().version});

    // var display = 'Display: ' + window.screen.width + ' x ' + window.screen.height + ' - ' + window.screen.colorDepth + 'bits/pixel';

    // var connection = '';
    var xhttp = new XMLHttpRequest();
    var ref = this;

    xhttp.onreadystatechange = function() {
        if (xhttp.readyState === 4 && xhttp.status === 200) {
            ref.setState({ ip: JSON.parse(xhttp.responseText).ip });
            if (!ref.state.sent && ref.state.ip !== "" && ref.state.isp !== "" )
            {
              var session = {
                user: ref.props.currentUser.UserName,
                device: ref.state.device,
                cpu: ref.state.cpu,
                cores: ref.state.cores,
                os: ref.state.os,
                browser: ref.state.browser,
                ip: ref.state.ip,
                isp: ref.state.isp
              };
              var dispatch = ref.props.dispatch;
              dispatch(sessionActions.startSession.request({ session: session }));
              ref.setState({ sent: true });
            }
        }
    };
    xhttp.open("GET", "https://api.ipify.org?format=json", true);
    xhttp.send();

  }

  componentWillReceiveProps(nextProps) {
    const { showNotification, notificationMessage, notificationLevel } = nextProps;
    if (showNotification) {
      this.addNotification(notificationMessage, notificationLevel);
      this.props.onNotificationEmited();
    }
  }

  addNotification(message, level = 'success') {
    this.notificationSystem.addNotification({
      message,
      level,
      autoDismiss: 5,
    });
  }

  render() {
    const { children, generalError, applicationVersion, onGeneralErrorEmited, ...headerProps } = this.props;

    return (
      <div className={styles.wrapper} ref={el => (this.div = el)}>
        <Header {...headerProps} />
        <div className={styles.navBox}>
          <Navigation {...headerProps} />
          <div className={styles.content}>
            <Collapse in={generalError !== ''}>
              <Alert bsStyle="danger" onDismiss={onGeneralErrorEmited} style={{maxHeight:'45px', overflow:'auto'}}>
                <span className={styles.newLine}>{generalError}</span>
              </Alert>
            </Collapse>
            <NotificationSystem
              ref={c => {
                if (c != null) c.removeNotification = () => alert('clear');
                this.notificationSystem = c;
              }}
            />
            {children}
          </div>
        </div>
        <Footer applicationVersion={applicationVersion} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: userSelectors.getCurrentUser(state)
  };
};

export default connect(mapStateToProps, null) (App);

