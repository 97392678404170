import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { push } from 'react-router-redux';
import CompanyDetails from './CompanyDetails';
import { companyTypes, companyActions, companySelectors } from '../../redux/company';
import { companyOrderDocumentActions } from '../../redux/companyOrderDocument';
import { companyFormSelectors, companyFormActions } from '../../redux/form/companyForm';
import { userRoleActions } from '../../redux/userRole';
import { userSelectors } from '../../redux/user';
import { applicationSelectors } from '../../redux/application';
import { modalActions } from '../../redux/modal';
import { isNullOrUndef } from '../../utils/utils';
import userRoleTypes from '../../constants/userRoleTypes';
import { referentialSelectors } from '../../redux/referentials';
import { orderActions } from '../../redux/order';
import { orderImportActions } from '../../redux/orderImport';
import { isDirty } from 'redux-form';
import { workflowStatusSelectors, workflowStatusTypes } from '../../redux/workflowStatus';

class CompanyDetailsContainer extends Component {
  static propTypes = {
    onLoadCompany: PropTypes.func.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      query: PropTypes.shape({
        duplicateFrom: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    location: PropTypes.shape({
      query: {},
    }),
  };

  componentDidMount() {
    const companyIdToFetch = this.getCompanyIdToFetch(this.props);
    if (companyIdToFetch > 0) {
      this.props.onLoadCompany(companyIdToFetch);
    }
  }
  componentDidUpdate(previousProps) {
    if (this.getCompanyId(previousProps) !== this.getCompanyId(this.props)) {
      const companyIdToFetch = this.getCompanyIdToFetch(this.props);
      if (companyIdToFetch > 0) {
        this.props.onLoadCompany(companyIdToFetch);
      }
    }
  }
  getDuplicateId = () => (this.props.location.query && Number(this.props.location.query.duplicateFrom)) || null;
  getCompanyId = props => Number(props.params.id);

  getCompanyIdToFetch = props => (this.getCompanyId(props) > 0 ? this.getCompanyId(props) : this.getDuplicateId(props));

  render() {
    const companyId = Number(this.props.params.id);
    return <CompanyDetails companyId={companyId} {...this.props} />;
  }
}

const isMemoNotEmpty = R.pipe(
  R.propOr(null, 'Memo'),
  R.uncurryN(1, JSON.parse),
  R.propOr([], 'blocks'),
  R.any(R.propSatisfies(R.complement(R.isEmpty), 'text')),
);

function mapStateToProps(state, { params: { id: companyId }, location }) {
  const { currentCompanyDocuments, currentCompanySubscriptions } = state.company;
  const company = companySelectors.getCompany(state, companyId);
  const showDetails = isNullOrUndef(location.query.show_details)
    ? isMemoNotEmpty(company)
    : location.query.show_details === 'true';

  const getInitialValues = companyFormSelectors.makeGetInitialValues(state);

  const { countryList, inciCalculationTypes, DistributionChannels } = state.referentials;
  const getWorkflowStatusList = workflowStatusSelectors.getWorkflowStatusListByCode('COMPANY_RP');
  // const RPStatus = company?company.RPStatus:''; //      getById(companyId).RPStatus: '';   // getCurrentCompanyRPStatus(state);
  return {
    requestingCurrentCompany: applicationSelectors.isLoading(state, companyTypes.COMPANY.REQUEST),
    savingCompany: applicationSelectors.isLoading(state, companyTypes.SAVE_COMPANY.REQUEST),
    initialValues: getInitialValues(companyId),
    currentCompanyDocuments,
    currentCompanySubscriptions,
    inciCalculationTypes,
    DistributionChannels,
    expertList: userSelectors.getUsersByRole(state, userRoleTypes.expert),
    saleRepList: userSelectors.getUsersByRole(state, userRoleTypes.saleRep),
    customerSupportList: userSelectors.getUsersByRole(state, userRoleTypes.customerSupport),
    isLoadingDetails: applicationSelectors.isLoading(
      state,
      companyTypes.SAVE_COMPANY.REQUEST,
      companyTypes.COMPANY.REQUEST,
      companyTypes.DELETE_COMPANY.REQUEST,
    ),
    inDuplicateMode: companyFormSelectors.getInDuplicateMode(state),
    isNew: companyFormSelectors.isNew(state),
    companyTitle: companySelectors.companyTitle(state),
    RPStatus: getInitialValues(companyId).RPStatus,
    countryList,
    companyList: companySelectors.getCompanyList(state),
    showDetails,
    languageList: referentialSelectors.getLanguageList(state),
    currencies: referentialSelectors.getCurrencies(state),
    isDirty: isDirty('companyForm')(state),
    workflowStatusList: getWorkflowStatusList(state),
};
}
function mapDispatchToProps(dispatch, ownProps) {
  const showDetails = ownProps.location.query.show_details === 'true';
  const CompanyId = ownProps.companyId;
  return {
    onLoadCompany: id => {
      dispatch(companyActions.company.request(id));
      dispatch(
        userRoleActions.listUserRoleForRoles.request([
          userRoleTypes.expert,
          userRoleTypes.customerSupport,
          userRoleTypes.saleRep,
        ]),
      );
    },
    onInitializePolling: () => {
      dispatch(orderActions.initializePolling.request())
    },
    onPoll: () => 
      dispatch(orderImportActions.poll.request()),
    onShowDetails: () => dispatch(push({ ...ownProps.location, query: { show_details: !showDetails } })),
    onUploadFile: data => {
      console.log('dispatch');
      dispatch(companyOrderDocumentActions.uploadCompanyOrderDocument.request(ownProps.params.id, data));
    },
    onSaveCompany: companyActions.submitCompanyForm,
    onChangeRPStatus: ({ Code }, id) =>
    {  
      console.log('Change RP Status');
      dispatch(companyActions.updateRPStatusAction({ WorkflowStatusCode: Code, CompanyId: id }));
    },
    deleteCompanyRequest: id => dispatch(companyActions.deleteCompany.request(id)),
    onDuplicate: () => dispatch(companyFormActions.duplicateCompany(ownProps.params.id)),
    onDeleteCompany: () =>
      dispatch(
        modalActions.showModal({
          modalType: 'CONFIRM',
          modalProps: {
            title: 'Are you sure ?',
            message: 'Are you sure you want to delete this company ?',
            onConfirm: () => dispatch(companyActions.deleteCompany.request(ownProps.params.id)),
          },
        }),
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyDetailsContainer);
