import { get, post, uploadFiles, del, download } from './apiUtils';

const defaultUrl = '/Companies/';
const productsUrl = '/Products/';
const distributorsUrl = '/Distributors/';
const distributionChannelsUrl = '/DistributionChannels/';
const ordersUrl = '/Orders/';
const documentsUrl = '/Documents/';
const rawMatsUrl = '/RawMats/';
const copyRMUrl = 'CopyRM';

export default {
  getCompanyList: () => get(defaultUrl),
  getCompany: id => get(`${defaultUrl}${id}`),
  getCompanyProducts: id => get(`${defaultUrl}${id}${productsUrl}`),
  getCompanySubscriptions: id => get(`${defaultUrl}${id}/Subscriptions`),
  // getCompanyRawMats: id => get(`${defaultUrl}${id}${rawMatsUrl}ALL`),
  getCompanyRawMats: payload => get(`${defaultUrl}${payload.id}${rawMatsUrl}${payload.filter}`),
  getCompanyDistributors: id => get(`${defaultUrl}${id}${distributorsUrl}`),
  getCompanyDistributionChannels: id => get(`${defaultUrl}${id}${distributionChannelsUrl}`),
  getCompanyOrders: id => get(`${defaultUrl}${id}${ordersUrl}`),
  getCompanyDocuments: id => get(`${defaultUrl}${id}${documentsUrl}`),
  uploadCompanyDocuments: (id, data) => uploadFiles(`${defaultUrl}${id}/Files`, data),
  saveCompany: data => post(`${defaultUrl}`, data),
  deleteCompany: id => del(`${defaultUrl}${id}`),
  removeDistributorFromCompany: (companyId, distributorId) =>
    del(`${defaultUrl}${companyId}${distributorsUrl}${distributorId}`),
  removeDistributionChannelFromCompany: (companyId, distributionChannelCode) =>
    del(`${defaultUrl}${companyId}${distributionChannelsUrl}${distributionChannelCode}`),
  copyRmToCompany: data => post(`${defaultUrl}${copyRMUrl}`, data),
  updateRPStatus: (data) => post(`${defaultUrl}${data.CompanyId}/RPStatus`, data),
  exportCompanyProductsByRawMats: (data) => {
    download(`${defaultUrl}${data.companyId}/ExportProductsUsingRawMats/`, '',
      { method: 'POST', body: JSON.stringify(data.rawMats),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      });
    // download(`${defaultUrl}${data.companyId}/ExportProductsUsingRawMats/?rawMatLst=${data.rawMats}`);
    // post(`${defaultUrl}${data.companyId}/ExportProductsUsingRawMats`, data.rawMats);
  },
};
