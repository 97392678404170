import React from 'react';
import { Grid, Col } from 'react-bootstrap';
import RawMatDocumentList from './RawMatDocumentList';
import DropzoneForm from '../../_Common/Forms/DropzoneForm';

const RawMatDocumentsTab = props =>
  (<div>
    <Grid fluid>
      <Col sm={12}>
        <RawMatDocumentList {...props} />
      </Col>
      {/* <Col sm={2}>
        <DropzoneForm {...props} />
      </Col> */}
    </Grid>
  </div>);

export default RawMatDocumentsTab;
