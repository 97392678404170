import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import { GeneralGrid, DateCellRenderer, TimeCellRenderer, EditDeleteButtonRenderer } from '../../../_Common/Grids';
import { workItemSelectors, workItemTypes, workItemActions } from '../../../../redux/workItem';
import { applicationSelectors } from '../../../../redux/application';
import SearchForm from './SearchForm';
import { userSelectors } from '../../../../redux/user';
import { isNullOrUndef } from '../../../../utils/utils';
import { modalActions } from '../../../../redux/modal';
import { taskActions } from '../../../../redux/task';
import WithPrivileges from '../../../_Common/WithPrivileges';
import userActionsCodes from '../../../../constants/userActionsCodes';
import { userRoleActions } from '../../../../redux/userRole';
import * as userRoles from '../../../../constants/userRoleTypes';
import DurationCellRenderer from '../../../_Common/Grids/DurationCellRenderer';

class DailyRecords extends Component {
  constructor(props) {
    super(props);
    let expert = null;
    if (props.currentUserActionCodes.find(currentUserActionCode => currentUserActionCode === userActionsCodes.CalmosExpertAction) !== undefined) {
      expert = props.user.UserName;
    }
    this.state = {
      searchCriteria: {
        expert,
        from: moment()
          .format(),
        to: moment()
          .format(),
      },
      from: moment()
        .format(),
      to: moment()
        .format(),
      expert,
    };
  }

  async componentDidMount() {
    await this.props.onLoad();
    const searchCriteria = this.state.searchCriteria;
    //this.props.getWorkItemList(searchCriteria);
  }

  search = () => {
    const searchCriteria = {
      from: this.state.from,
      to: this.state.to,
      expert: this.state.expert,
    };
    this.setState({ searchCriteria });
    this.props.getWorkItemList(searchCriteria);
  };

  fromChanged = from => {
    this.setState({ from });
  };

  toChanged = to => {
    this.setState({ to });
  };

  expertChanged = expert => {
    this.setState({ expert });
  };

  render() {
    const {
      isLoading,
      getWorkItemSelector,
      onDeleteButtonClick,
      onEditButtonClick,
    } = this.props;

    const workItemList = getWorkItemSelector(this.state.searchCriteria);

    return (
      <WithPrivileges
        requiredActionCodes={[userActionsCodes.CalmosAdminAction, userActionsCodes.CalmosSuperAdminAction, userActionsCodes.CalmosExpertAction]}>
        {isAllowedToEditAdd => {
          const columns = [
            {
              headerName: I18n.t('dailyRecords.orderNr'),
              field: 'OrderNr',
              width: 80,
              sort: 'asc',
              sortedAt: 3,
            },
            {
              headerName: I18n.t('dailyRecords.company'),
              field: 'CompanyName',
              sort: 'asc',
              sortedAt: 2,
            },
            {
              headerName: I18n.t('dailyRecords.productCode'),
              field: 'ProductCode',
              width: 80,
              sort: 'asc',
              sortedAt: 4,
            },
            {
              headerName: I18n.t('dailyRecords.productName'),
              field: 'ProductName',
            },
            {
              headerName: I18n.t('dailyRecords.processType'),
              field: 'ProcessType',
              width: 80,
              sort: 'asc',
              sortedAt: 5,
            },
            {
              headerName: I18n.t('dailyRecords.region'),
              field: 'Region',
              width: 80,
              sort: 'asc',
              sortedAt: 6,
            },
            {
              headerName: I18n.t('dailyRecords.task'),
              field: 'TaskName',
              sort: 'asc',
              sortedAt: 7,
            },
            {
              headerName: I18n.t('orderTask.expert'),
              field: 'Expert',
              width: 70,
            },
            {
              headerName: I18n.t('dailyRecords.expectedDuration'),
              field: 'ExpectedDuration',
              cellRenderer: DurationCellRenderer,
              width: 80,
            },
            {
              headerName: I18n.t('dailyRecords.startDate'),
              field: 'StartedOn',
              cellRenderer: DateCellRenderer,
              cellRendererParams: {
                includeHours: false,
              },
              width: 85,
              sort: 'desc',
              sortedAt: 1,
            },
            {
              headerName: I18n.t('dailyRecords.startTime'),
              field: 'StartedOn',
              cellRenderer: TimeCellRenderer,
              width: 85,
              suppressSorting: true,
            },
            {
              headerName: I18n.t('dailyRecords.duration'),
              field: 'Duration',
              cellRenderer: DurationCellRenderer,
              width: 80,
            },
            {
              headerName: I18n.t('dailyRecords.endTime'),
              field: 'FinishedOn',
              cellRenderer: TimeCellRenderer,
              width: 85,
            },
            {
              headerName: I18n.t('general.actions'),
              cellRendererFramework: EditDeleteButtonRenderer,
              suppressSizeToFit: true,
              hide: !isAllowedToEditAdd,
              width: 70,
              cellRendererParams: {
                onDelete: ({ WorkItemId }) => onDeleteButtonClick({
                  WorkItemId,
                  searchCriteria: this.state.searchCriteria,
                }),
                onEdit: ({ WorkItemId }) => onEditButtonClick({
                  WorkItemId,
                  searchCriteria: this.state.searchCriteria,
                }),
              },
            },
          ];

          return (<Grid fluid>
            <Row style={{ marginBottom: '5px' }}>
              <SearchForm
                from={this.state.from}
                to={this.state.to}
                expert={this.state.expert}
                search={this.search}
                fromChanged={this.fromChanged}
                toChanged={this.toChanged}
                expertChanged={this.expertChanged}
                user={this.props.user}
                currentUserActionCodes={this.props.currentUserActionCodes}
              />
            </Row>
            <Row>
              <Col sm={12}>
                <GeneralGrid
                  data={workItemList}
                  columns={columns}
                  keyName="WorkItemId"
                  isLoading={isLoading}
                  onRefresh={() => this.search(this.state.searchCriteria)}
                />
              </Col>
            </Row>
          </Grid>);
        }}
      </WithPrivileges>
    );
  }
}

const mapStateToProps = (state, props) => {
  const getWorkItemSelector = workItemSelectors.makeGetByQuery(state);
  return {
    getWorkItemSelector,
    isLoading: applicationSelectors.isLoading(state, workItemTypes.WORK_ITEM_LIST.REQUEST),
    user: userSelectors.getCurrentUser(state),
    currentUserActionCodes: userSelectors.getCurrentUserActionCodes(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad: () => {
    dispatch(userRoleActions.listUserRoleForRoles.request([userRoles.default.expert]));
  },
  getWorkItemList: searchCriteria => dispatch(workItemActions.listWorkItems.request(searchCriteria)),
  onEditButtonClick: ({ WorkItemId, searchCriteria }) =>
    dispatch(
      modalActions.showWorkItemFormModal({
        WorkItemId,
        searchCriteria,
      }),
    ),
  onDeleteButtonClick: ({ WorkItemId, searchCriteria }) => dispatch(taskActions.deleteTask.request({
    WorkItemId,
    searchCriteria,
  })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DailyRecords);

DailyRecords.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getWorkItemSelector: PropTypes.func.isRequired,
  getWorkItemList: PropTypes.func.isRequired,
  user: PropTypes.shape({
    UserName: PropTypes.string.isRequired,
  }).isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  currentUserActionCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
};
