import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Col, Grid, Form, Row, ButtonToolbar, Button} from 'react-bootstrap';
import {Field, isPristine, isSubmitting, reduxForm, submit} from 'redux-form';
import {I18n, Translate} from 'react-redux-i18n';
import {compose} from 'recompose';
import {CheckBoxField, InputField} from '../../../_Common/Forms';
import {categoryFormSelectors, categoryFormActions} from '../../../../redux/form/categoryForm';
import {referentialSelectors, referentialsTypes} from '../../../../redux/referentials';
import {applicationSelectors} from '../../../../redux/application';
import formNames from '../../../../constants/formNames';
import {isNumberField} from '../../../../utils/fieldValidation';
import {css} from "glamor";
import BaseLoader from "../../BaseLoader";
import ProductUseTypeSelectField from '../../Modals/ProductCategoryModal/ProductUseTypeSelectField';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
};

const formName = formNames.category;
const CategoryForm = props => {
  const {onSubmit, handleSubmit, closeModal, onSaveButtonClick, isSaveDisabled, isLoading } = props;

  return (
    <BaseLoader isLoading={isLoading}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid fluid>
          <Row>
            <Col sm={1}>
              <Field name="Code" label={I18n.t('category.code')} component={InputField}/>
            </Col>
            <Col sm={8}>
              <Field name="Description" label={I18n.t('category.description')} component={InputField}/>
            </Col>            
            <Col sm={3}>
                <Field name="IsActive" label={I18n.t('category.isactive')}
                 component={CheckBoxField}/>
              </Col>
            </Row>
            <Row>
            <Col sm={2}>
              <Field
                name="UseSite"
                label={I18n.t('category.useSite')}
                component={InputField}
              />
            </Col>
            <Col sm={1}>
              <Field
                name="UseQuantity"
                label={I18n.t('category.useQuantity')}
                component={InputField}
                inputGroupLabel={'g'}
              />
            </Col>
            <Col sm={1}>
              <Field
                name="UseFrequency"
                label={`${I18n.t('category.useFrequency')}`}
                component={InputField}
                inputGroupLabel={'/ day'}
                validate={isNumberField}
              />
            </Col>
            <Col sm={2}>
              <Field
                name="UseSurface"
                label={I18n.t('category.useSurface')}
                component={InputField}
                inputGroupLabel={'cm²'}
              />
            </Col>
            <Col sm={1}>
              <Field
                name="UseRetentionFactor"
                label={`${I18n.t('category.useRetentionFactor')}`}
                component={InputField}
                validate={isNumberField}
              />
            </Col>
            <Col sm={1}>
              <Field
                component={ProductUseTypeSelectField}
                label={`${I18n.t('category.useType')}`}
                name="ProductUseTypeCode"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={1}>
              <Field
                name="SensitizationAssessmentFactors"
                label={`${I18n.t('category.sensitizationAssessmentFactors')}`}
                component={InputField}
                validate={isNumberField}
              />
            </Col>
            <Col sm={1}>
              <Field
                name="SensitizationQuantity"
                label={`${I18n.t('category.sensitizationQuantity')}`}
                component={InputField}
                validate={isNumberField}
              />
            </Col>
            <Col sm={1}>
              <Field
                name="BodyWeight"
                label={`${I18n.t('category.bodyWeight')}`}
                component={InputField}
                validate={isNumberField}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Field name="IsInhalable" label={I18n.t('category.isInhalable')}
                     component={CheckBoxField}/>
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Field
                name="AmountSprayed"
                label={I18n.t('category.amountSprayed')}
                component={InputField}
                inputGroupLabel={'g'}
              />
            </Col>
            <Col sm={2}>
              <Field
                name="AirborneFraction"
                label={I18n.t('category.airborneFraction')}
                component={InputField}
                inputGroupLabel={'%'}
              />
            </Col>
            <Col sm={2}>
              <Field
                name="DistributionVolume1"
                label={I18n.t('category.distributionVolume1')}
                component={InputField}
                inputGroupLabel={'m³'}
              />
            </Col>
            <Col sm={2}>
              <Field
                name="ExposureTime1"
                label={I18n.t('category.exposureTime1')}
                component={InputField}
                inputGroupLabel={'min'}
              />
            </Col>
            <Col sm={2}>
              <Field
                name="DistributionVolume2"
                label={I18n.t('category.distributionVolume2')}
                component={InputField}
                inputGroupLabel={'m³'}
              />
            </Col>
            <Col sm={2}>
              <Field
                name="ExposureTime2"
                label={I18n.t('category.exposureTime2')}
                component={InputField}
                inputGroupLabel={'min'}
              />
            </Col>
            </Row>
            <Row>
            <Col sm={2}>
              <Field
                name="HumanBreathMinuteVolume"
                label={I18n.t('category.humanBreathMinuteVolume')}
                component={InputField}
                inputGroupLabel={'l/min'}
              />
            </Col>
            <Col sm={2}>
              <Field
                name="MeanApplicationPerDay"
                label={I18n.t('category.meanApplicationPerDay')}
                component={InputField}
                inputGroupLabel={'/day'}
              />
            </Col>
            <Col sm={2}>
              <Field
                name="FractionReachingAlveoli"
                label={I18n.t('category.fractionReachingAlveoli')}
                component={InputField}
                inputGroupLabel={'%'}
              />
            </Col>
            <Col sm={2}>
              <Field
                name="DefaultFactorSubstanceExchange"
                label={I18n.t('category.defaultFactorSubstanceExchange')}
                component={InputField}
                inputGroupLabel={'%'}
              />
            </Col>
          </Row>
          <br/>
          <Row>
            <Col sm={6}>
              <Field name="UseDescription" label={I18n.t('product.useDescription')} component={InputField}/>
            </Col>
            <Col sm={6}>
              <Field name="ReasonableUse" label={I18n.t('product.reasonableUse')} component={InputField}/>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Field name="NormalUse" label={I18n.t('product.normalUse')} component={InputField}/>
            </Col>
            <Col sm={6}>
              <Field name="Misuse" label={I18n.t('product.misuse')} component={InputField}/>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Field name="TargetPopulation" label={I18n.t('product.targetPopulation')} component={InputField}/>
            </Col>
            <Col sm={6}>
              <Field name="UseDescription" label={I18n.t('product.useDescription')} component={InputField}/>
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Field name="IsDermalAbsorption100" label={I18n.t('category.isDermalAbsorption')}
                     component={CheckBoxField}/>
            </Col>
          </Row>
        </Grid>
        <div className={styles.buttonPanel}>
          <ButtonToolbar>
            <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
              <i className="fa fa-times" />&nbsp;
              <Translate value="general.close" />
            </Button>
            <Button bsSize="small" bsStyle="success" type="button" onClick={onSaveButtonClick} disabled={isSaveDisabled}>
              <Translate value="general.save" />&nbsp;
              <i className="fa fa-arrow-circle-right" />
            </Button>
          </ButtonToolbar>
        </div>
      </Form>
    </BaseLoader>
  );
};

const mapStateToProps = (state, {categoryId}) => {
  const isFormSubmitting = isSubmitting(formName)(state);
  const isFormPristine = isPristine(formName)(state);

  return {
    cpsrTypes: referentialSelectors.getCpsrTypeList(state),
    initialValues: categoryFormSelectors.getInitialValues(state)(categoryId),
    isLoadingCpsrTypes: applicationSelectors.isLoading(state, referentialsTypes.ALL_REFERENTIALS.REQUEST),
    isLoading: isFormSubmitting,
    isSaveDisabled: isFormSubmitting || isFormPristine,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: data => categoryFormActions.submitCategoryForm(data, dispatch),
  onSaveButtonClick: () => {
    dispatch(submit(formName));
  },
});

CategoryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cpsrTypes: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isLoadingCpsrTypes: PropTypes.bool.isRequired,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(CategoryForm);
