import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import {SelectionGrid, EditButtonRenderer, LinkCellRenderer} from '../_Common/Grids';
import config from '../../config';

const API_ROOT = config.baseUrl;


const columns = [
   {
      headerName: I18n.t('dashboard.name'),
      field: 'Title',
      width: 200,
      cellRendererFramework: LinkCellRenderer,
      cellRendererParams: {
        textKey: 'Title',
        linkFunc : (data) => { return config.baseUrl + '/DashboardTypes/' + data.DashboardTypeId; } ,
        downloadable: true
      }
    },
    {
      headerName: 'File type',
      field: 'FileExtention',
      width: 80
    },      
    {
      headerName: 'Description',
      field: 'Description'
    }
];


const DashboardsList = (props) => {
  const {
    dashboardList,
    downloadDashboard ,
    onDashboardSelected,
    requestingDashboardList,
    selectedId,
    getDashboardList,
  } = props;
  return (
    <SelectionGrid
      data={dashboardList}
      //onSelected={onDashboardSelected}
      onRefresh={getDashboardList}
      isLoading={requestingDashboardList}
      columns={columns}
      onFetchData={props.onFetchData}
      keyName="DashboardTypeId"
      showAddButton={false}
    />
  );
};

DashboardsList.defaultProps = {
  selectedId: '',
};

DashboardsList.propTypes = {
  dashboardList: PropTypes.arrayOf(PropTypes.shape({
    DashboardTypeId: PropTypes.number,
    Code: PropTypes.string,
    Value: PropTypes.string,
    Url: PropTypes.string,
    Description: PropTypes.string
  })),
  getDashboardList: PropTypes.func,
  downloadDashboard: PropTypes.func,
  //onDashboardSelected: PropTypes.func,
  requestingDashboardList: PropTypes.bool.isRequired,
  selectedId: PropTypes.string,
};

export default DashboardsList;
