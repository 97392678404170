import { handleActions, combineActions } from 'redux-actions';
import { merge } from 'lodash';
import * as companyTypes from './companyTypes';
import * as companyDocumentTypes from '../companyDocument/companyDocumentTypes';
import { companyDistributorAgreementTypes } from '../companyDistributorAgreement';
import { rawMatTypes } from '../rawMat';

export const initialState = {
  companyList: [],
  currentCompanySubscriptions: [],
  currentCompanyProducts: [],
  currentCompanyRawMats: [],
  currentCompanyDistributors: [],
  currentCompanyOrders: [],
  currentCompany: {},
  currentCompanyDistributionChannels: [],
  byId: {},
  list: {
    allIds: [],
  },
};

export default handleActions(
  {
    // [companyTypes.COMPANY_LIST.SUCCESS]: (state, { payload }) => ({ ...state, companyList: payload }),
    // [companyTypes.COMPANY.SUCCESS]: (state, { payload }) => ({ ...state, currentCompany: payload }),
    [companyTypes.COMPANY_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      byId: merge({}, state.byId, payload.entities.companies),
      list: {
        ...state.list,
        allIds: payload.result,
      },
    }),
    [combineActions(
      companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_DISTRIBUTOR.SUCCESS,
      rawMatTypes.RAWMAT.SUCCESS,
    )]: (state, { payload }) => ({
      ...state,
      byId: { ...state.byId, ...payload.entities.companies },
      list: {
        ...state.list,
        allIds: payload.entities.companies
          ? [...new Set([...state.list.allIds, ...Object.keys(payload.entities.companies).map(Number)])]
          : state.list.allIds,
      },
    }),
    [companyTypes.COMPANY.SUCCESS]: (state, { payload }) => ({
      ...state,
      byId: merge({}, state.byId, payload.entities.companies),
      list: {
        ...state.list,
        allIds: [...new Set([...state.list.allIds, payload.result])],
      },
    }),
    [companyDocumentTypes.COMPANY_DOCUMENT_LIST_FOR_COMPANY.SUCCESS]: (state, { payload, meta: { CompanyId } }) => ({
      ...state,
      byId: {
        ...state.byId,
        [CompanyId]: {
          ...state.byId[CompanyId],
          CompanyDocuments: payload.result,
        },
      },
    }),

    // To be normalized
    [companyTypes.COMPANY_PRODUCT_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      currentCompanyProducts: payload,
    }),
    
    [companyTypes.COMPANY_SUBSCRIPTIONS_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      currentCompanySubscriptions: payload,
    }),
    [companyTypes.COMPANY_RAW_MAT_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      currentCompanyRawMats: payload,
    }),
    [companyTypes.COMPANY_DISTRIBUTOR_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      currentCompanyDistributors: payload,
    }),
    [companyTypes.COMPANY_DISTRIBUTION_CHANNEL_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      currentCompanyDistributionChannels: payload,
    }),
    [companyTypes.COMPANY_ORDER_LIST.SUCCESS]: (state, { payload }) => ({ ...state, currentCompanyOrders: payload }),
    // [companyTypes.COMPANY_DOCUMENT_LIST.SUCCESS]: (state, { payload }) => ({
    //   ...state,
    //   currentCompanyDocuments: payload,
    // }),
    [companyTypes.DELETE_COMPANY.SUCCESS]: state => ({ ...state, currentCompany: {} }),
    [companyTypes.NEW_COMPANY]: state => ({
      ...state,
      currentCompany: {
        CompanyId: -1,
        IsActive: true,
      },
      currentCompanyProducts: [],
      currentCompanySubscriptions: [],
      currentCompanyDistributors: [],
      currentCompanyOrders: [],
      currentCompanyDistributionChannels: [],
    }),
  },
  initialState,
);
