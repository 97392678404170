import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid, EditDeleteButtonRenderer } from '../../_Common/Grids';
import WithPrivileges from '../../_Common/WithPrivileges';
import userActionsCodes from '../../../constants/userActionsCodes';
import DurationCellRenderer from '../../_Common/Grids/DurationCellRenderer';

const TaskTypeList = props => {
  const { taskTypeList, isLoading, getTaskTypeList, onEditButtonClick, onAddButtonClick, onDeleteButtonClick } = props;

  return (
    <WithPrivileges requiredActionCodes={[userActionsCodes.CalmosAdminAction, userActionsCodes.CalmosSuperAdminAction]}>
      {isAllowedToEditAdd => {
        const columns = [
          {
            headerName: I18n.t('taskType.processTypeCode'),
            field: 'ProcessType.ProcessTypeCode',
            width: 70,
            sort: 'asc',
            sortedAt: 1
          },
          {
            headerName: I18n.t('taskType.region'),
            field: 'ProcessType.RegionValue',
            width: 50,
            sort: 'asc',
            sortedAt: 2
          },
          {
            headerName: I18n.t('taskType.processDescription'),
            field: 'ProcessType.Description',
            width: 70,
            sort: 'asc',
            sortedAt: 1
          },
          {
            headerName: I18n.t('taskType.name'),
            field: 'Name',
          },
          {
            headerName: I18n.t('taskType.expectedDuration'),
            field: 'ExpectedDuration',
            width: 40,
            cellRenderer: DurationCellRenderer,
          },
          {
            headerName: I18n.t('taskType.deliveryTime'),
            field: 'DeliveryTime',
            width: 40,
            // cellRenderer: DurationCellRenderer,
          },
          {
            headerName: I18n.t('taskType.weight'),
            field: 'Weight',
            width: 30,
          },
          {
            headerName: I18n.t('taskType.orderNr'),
            field: 'OrderNr',
            width: 30,
            sort: 'asc',
            sortedAt: 3
          },
          {
            headerName: I18n.t('taskType.code'),
            field: 'Code',
            width: 70,
          },
          {
            headerName: I18n.t('general.actions'),
            cellRendererFramework: EditDeleteButtonRenderer,
            suppressSizeToFit: true,
            width: 70,
            cellRendererParams: {
              onDelete: ({ TaskTypeId }) => onDeleteButtonClick(TaskTypeId),
              onEdit: ({ TaskTypeId }) => onEditButtonClick(TaskTypeId),
            },
          },
        ];

        return (
          <SelectionGrid
            data={taskTypeList}
            keyName="TaskTypeId"
            columns={columns}
            onRefresh={getTaskTypeList}
            showLoadingIcon={isLoading}
            onAdd={onAddButtonClick}
            stopEditingWhenGridLosesFocus
            singleClickEdit
            showAddButton={isAllowedToEditAdd}
            showFilterRow
          />
        );
      }}
    </WithPrivileges>
  );
};

TaskTypeList.propTypes = {
  taskTypeList: PropTypes.array, // eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  getTaskTypeList: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
};

export default TaskTypeList;
