import moment from 'moment';
import PropTypes from 'prop-types';
import { isNullOrUndef } from '../../../utils/utils';

const DateCellRenderer = ({ value, includeHours }) => {
  const dateFormat = includeHours ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
  if (isNullOrUndef(value)) return '';
  const resultValue = moment.utc(value);
  if (resultValue.isValid()) {
    resultValue.local();
  }
  return resultValue.isValid() ? resultValue.format(dateFormat) : value;
};

DateCellRenderer.defaultProps = {
  includeHours: true,
  value: '',
};

DateCellRenderer.propTypes = {
  value: PropTypes.string,
  includeHours: PropTypes.bool,
};

export default DateCellRenderer;
