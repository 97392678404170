import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { companyActions, companyTypes } from '../../../redux/company';
import { applicationSelectors } from '../../../redux/application';
import { workflowStatusSelectors } from '../../../redux/workflowStatus';

import CompanySubscriptionsTab from './CompanySubscriptionsTab';

class CompanySubscriptionsContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    companyId: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired, //eslint-disable-line
  };

  componentDidMount() {
    if (this.props.companyId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.companyId !== this.props.companyId && this.props.companyId > 0) {
      debugger;
      this.props.onLoad();
    }
  }

  render() {
    return <CompanySubscriptionsTab {...this.props} />;
  }
}

const mapStateToProps = state => {
  const {
    company: { currentCompanySubscriptions },
  } = state;  
  return {
    workflowStatusNameById: workflowStatusSelectors.getById(state),
    currentCompanySubscriptions,
    requestingCompanySubscriptionsList: applicationSelectors.isLoading(state, companyTypes.COMPANY_SUBSCRIPTIONS_LIST.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, { companyId }) => ({
  onLoad: () => dispatch(companyActions.listCompanySubscriptions.request(companyId)),
  onRefresh: () => dispatch(companyActions.listCompanySubscriptions.request(companyId))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySubscriptionsContainer);
