import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid, EditButtonRenderer } from '../../_Common/Grids';
import WithPrivileges from '../../_Common/WithPrivileges';
import userActionsCodes from '../../../constants/userActionsCodes';
import DurationCellRenderer from '../../_Common/Grids/DurationCellRenderer';
import PriceCellRenderer from '../../_Common/Grids/PriceCellRenderer';
import TrueYesCellRenderer from '../../_Common/Grids/TrueYesCellRenderer';

const ProcessTypeList = props => {
  const { processTypeList, isLoading, getProcessTypeList, onEditButtonClick, onAddButtonClick, currencies } = props;

  return (
    <WithPrivileges requiredActionCodes={[userActionsCodes.CalmosAdminAction, userActionsCodes.CalmosSuperAdminAction]}>
      {isAllowedToEditAdd => {
        const columns = [
          {
            headerName: I18n.t('processType.processTypeId'),
            field: 'ProcessTypeId',
            width: 40
          },
          {
            headerName: I18n.t('processType.processTypeCode'),
            field: 'ProcessTypeCode',
          },
          {
            headerName: I18n.t('processType.name'),
            field: 'Description',
          },
          {
            headerName: I18n.t('processType.region'),
            field: 'RegionValue',
          },
          {
            headerName: I18n.t('processType.currency'),
            field: 'UnitPriceCurrency',
            width: 40,
          },
          {
            headerName: I18n.t('processType.unitPrice'),
            field: 'UnitPrice',
            cellRenderer: PriceCellRenderer,
            cellRendererParams: {
              currencies,
              currencyLabel: 'UnitPriceCurrency',
            },
          },
          {
            headerName: I18n.t('processType.pricePerShade'),
            field: 'PricePerShade',
            cellRenderer: TrueYesCellRenderer,
            width: 60
          },          {
            headerName: I18n.t('processType.hasTask'),
            field: 'HasTask',
            cellRenderer: TrueYesCellRenderer,
            width: 60
          },
          {
            headerName: I18n.t('processType.expectedDuration'),
            field: 'ExpectedDuration',
            cellRenderer: DurationCellRenderer,
          },
          {
            headerName: I18n.t('general.actions'),
            cellRendererFramework: EditButtonRenderer,
            suppressSizeToFit: true,
            width: 70,
            cellRendererParams: {
              onClick: ({ ProcessTypeId }) => onEditButtonClick(ProcessTypeId),
            },
          },
        ];

        return (
          <SelectionGrid
            data={processTypeList}
            keyName="ProcessTypeId"
            columns={columns}
            onRefresh={getProcessTypeList}
            showLoadingIcon={isLoading}
            onAdd={onAddButtonClick}
            stopEditingWhenGridLosesFocus
            showAddButton={isAllowedToEditAdd}
            showFilterRow
          />
        );
      }}
    </WithPrivileges>
  );
};

ProcessTypeList.propTypes = {
  processTypeList: PropTypes.array, // eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  getProcessTypeList: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
};

export default ProcessTypeList;
