import PropTypes from 'prop-types';
import React from 'react';
import { Col, Grid } from 'react-bootstrap';
import { map, propOr, compose, filter, values, join } from 'ramda';
import { I18n } from 'react-redux-i18n';
import ProductClpText from './ProductClpText';
import GoToSubstancesButton from './GoToSubstancesButton';
import { SelectionGrid, PercentageCellRenderer, LinkCellRenderer, MeasureCellRenderer, LimitedMeasureCellRenderer, TooltipRenderer } from '../../_Common/Grids';
import * as R from 'ramda';
import ButtonDropDown from '../../_Common/ButtonDropDown';

const clpValues = [
  'IsClassified',
  'SkinEyeCorrosionCat1A',
  'SkinEyeCorrosionCat1B',
  'SkinEyeCorrosionCat1C',
  'SkinIrritantCat2',
  'SkinIrritantCat3',
  'EyeDamageCat1',
  'EyeIrritantCat2A',
  'EyeIrritantCat2B',
  'StotRespirIrritantCat2',
  'SkinSensitizer',
  'InhalationSensitizer',
];

const lessThan100 = R.complement(R.lte(100));
const getCellStyle = R.ifElse(
  R.propSatisfies(lessThan100, 'value'),
  R.always({ color: 'red', fontWeight: 'bold' }),
  R.always({ color: 'black', fontWeight: 'normal' }),
);

const makeGetClpValue = clpValueArray => substanceClp => {
  if (!substanceClp) return '';

  return compose(
    join(' - '),
    filter(Boolean),
    values,
    map(value => (propOr(false, value, substanceClp) === true ? I18n.t(`clp.${value}`) : null)),
  )(clpValueArray);
};

const MosCalculationTab = props => {
  const {
    currentProductMosCalculationList,
    currentProductCategories,
    isLoadingMosCalculationTab,
    onRefresh,
    onCalculateMoSClicked,
    onRowDoubleClicked,
    productId,
  } = props;

  const mainCat = currentProductCategories.find( cat => cat.IsMain);
  const getClpValue = makeGetClpValue(clpValues);

  const columns = [
    {
      headerName: I18n.t('substance.inciName'),
      field: 'InciName',
      cellRendererFramework: LinkCellRenderer, 
      cellRendererParams: {
        textKey: 'InciName',
        linkKey: 'SubstanceUrl',
        downloadable: false
      },
      suppressResize: false
    },
    {
      headerName: I18n.t('substance.casNr'),
      field: 'CasNr',
      cellRendererFramework: TooltipRenderer
    },
    {
      headerName: I18n.t('general.percentageInProduct'),
      field: 'PercentageInProduct',
      cellRenderer: PercentageCellRenderer,
      width: 100,
      sort: 'desc',
      sortedAt: 2,
    },
    {
      headerName: I18n.t('substance.status'),
      field: 'Status',
      cellRenderer: ({ value }) => {
        return value.substring(10).toLowerCase();
      },
      width: 100
    },    {
      headerName: `${I18n.t('substance.sed')} (${I18n.t('uom.sed')})`,
      field: 'Sed',
      cellRenderer: MeasureCellRenderer,
      cellRendererParams: {
        nbDecimal: 6,
      },
      width: 100
    },
    {
      headerName: I18n.t('substance.noael'),
      field: 'NoaelValue',
      cellRenderer: MeasureCellRenderer,
      cellRendererParams: {
        uomField: 'NoaelUom',
      },
    },
    {
      headerName: I18n.t('substance.absorptionRate'),
      field: 'AbsorptionRateValue',
      cellRenderer: MeasureCellRenderer,
      cellRendererParams: {
        uomField: 'AbsorptionRateUom',
      },
      width: 100
    },
    {
      headerName: `${I18n.t('substance.mosSystemic')}`,
      // headerComponentParams: { template: '<span ref="eText" class="ag-header-cell-text" role="columnheader">'+`${I18n.t('substance.mosSystemic')}`+'</span>'},
      field: 'Mos',
      cellRenderer: LimitedMeasureCellRenderer,
      cellRendererParams: {
        nbDecimal: 0,
        max: 1000000
      },
      cellStyle: getCellStyle
      // valueGetter: ({ data }) => data.Mos + data.MosInhal,
    },
    // {
    //   headerName: `${I18n.t('substance.sedInhal')} (${I18n.t('uom.sed')})`,
    //   field: 'SedInhal',
    //   cellRenderer: MeasureCellRenderer,
    //   cellRendererParams: {
    //     nbDecimal: 6,
    //   },
    // },
    // {
    //   headerName: I18n.t('substance.noaelInhal'),
    //   field: 'NoaelForInhalValue',
    //   cellRenderer: MeasureCellRenderer,
    //   cellRendererParams: {
    //     uomField: 'NoaelForInhalUom',
    //   },
    // },
    // {
    //   headerName: `${I18n.t('substance.mosInhal')}`,
    //   // headerComponentParams: { template: '<span ref="eText" class="ag-header-cell-text" role="columnheader">'+`${I18n.t('substance.mosInhal')}`+'</span>'},
    //   field: 'MosInhal',
    //   cellRenderer: LimitedMeasureCellRenderer,
    //   cellRendererParams: {
    //     nbDecimal: 0,
    //     max: 1000000
    //   },
    //   cellStyle: getCellStyle
    // },
    
    {
      headerName: I18n.t('substance.Nesil'),
      field: 'Nesil',
      cellRenderer: LimitedMeasureCellRenderer,
      cellRendererParams: {
        nbDecimal: 0,
        max: 1000000
      },
      width: 100
    },
    {
      headerName: I18n.t('substance.aelcel'),
      field: 'SensitizationIndicator',
    },
    {
      headerName: I18n.t('substance.clp'),
      valueGetter: ({ data }) => getClpValue(data.SubstanceClp),
      cellRendererFramework: TooltipRenderer,
    },
    {
      headerName: I18n.t('substance.comment'),
      field: 'NoaelComment',
      cellRendererFramework: TooltipRenderer,
    },
    {
      headerName: I18n.t('substance.type'),
      sort: 'asc',
      sortedAt: 1,
      valueGetter: ({ data }) => I18n.t(`substanceTypes.${data.SubstanceType}`),
      width: 100
    },
  ];


  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <Grid fluid>
          <Col sm={2} style={{ marginRight: '10px' }}>
            <GoToSubstancesButton productId={productId} />
          </Col>
          {/* <Col sm={2}> */}
            <ButtonDropDown
              options={currentProductCategories}
              labelKey="CategoryDescription"
              valueKey="CategoryCode"
              buttonText="Calc."
              onButtonClick={onCalculateMoSClicked}
              selectedOption={mainCat?mainCat.CategoryCode: ""}
            />
          {/* </Col> */}
          <Col sm={6}>
            <ProductClpText productId={productId} />
          </Col>
        </Grid>
      </div>
      <SelectionGrid
        data={currentProductMosCalculationList}
        columns={columns}
        keyName="SubstanceId"
        isLoading={isLoadingMosCalculationTab}
        rowDoubleClicked={onRowDoubleClicked}
        onRefresh={onRefresh}
        showAddButton={false}
      />
    </div>
  );
};

MosCalculationTab.propTypes = {
  currentProductMosCalculationList: PropTypes.array.isRequired, //eslint-disable-line
  isLoadingMosCalculationTab: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onRowDoubleClicked: PropTypes.func.isRequired,
  onCalculateMoSClicked: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
};

export default MosCalculationTab;
