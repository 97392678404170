import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import DelayedLoading from '../../_Common/DelayedLoading';
import LoadingButton from '../../_Common/LoadingButton';
import ShadeListCopyButton from './ShadeListCopyButton';
import ShadeListContainer from './_ShadeListContainer';

const ShadeTab = props => {
  const {
    productId,
    onCompoByRmButtonClick,
    buttonsDisabled,
    buttonsDisabledByValidedComposition,
    onCompoByInciButtonClick,
    onUpdateInciButtonClick,
    onUpdateMaxQtyButtonClick,
    isUpdatingMaxQty,
    onLatinAmericaAnastasiaCompoClick,
    isLoadingShadeInciModal,
    isLoadingShadeRmModal,
  } = props;
  return (
    <div>
      <div {...css({ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', marginRight: '10px' })}>
        <ButtonToolbar>
          <DelayedLoading isLoading={isLoadingShadeRmModal}>
            {({ isLoading }) => (
              <LoadingButton
                bsSize="small"
                onClick={onCompoByRmButtonClick}
                disabled={buttonsDisabled}
                isLoading={isLoading}
              >
                {I18n.t('shade.compoByRM')}
              </LoadingButton>
            )}
          </DelayedLoading>
          <DelayedLoading isLoading={isLoadingShadeInciModal}>
            {({ isLoading }) => (
              <LoadingButton
                bsSize="small"
                onClick={onCompoByInciButtonClick}
                disabled={buttonsDisabled}
                isLoading={isLoading}
              >
                {I18n.t('shade.compoByInci')}
              </LoadingButton>
            )}
          </DelayedLoading>
          {/* <Button
            bsSize="small"
            bsStyle="primary"
            onClick={onUpdateInciButtonClick}
            disabled={buttonsDisabled || buttonsDisabledByValidedComposition}
          >
            {I18n.t('shade.updateInciList')}
          </Button> */}
          <LoadingButton
            bsSize="small"
            bsStyle="primary"
            isLoading={isUpdatingMaxQty}
            onClick={onUpdateMaxQtyButtonClick}
            disabled={buttonsDisabled || buttonsDisabledByValidedComposition}
          >
            {I18n.t('shade.updateMaxQty')}
          </LoadingButton>
          <Button bsSize="small" onClick={onLatinAmericaAnastasiaCompoClick} disabled={buttonsDisabled}>
            {I18n.t('shade.anastasiaCompo')}
          </Button>
        </ButtonToolbar>
        <ShadeListCopyButton productId={productId} />
      </div>
      <ShadeListContainer
        productId={productId}
        buttonsDisabledByValidedComposition={buttonsDisabledByValidedComposition}
      />
    </div>
  );
};

ShadeTab.propTypes = {
  productId: PropTypes.number.isRequired,
  onCompoByRmButtonClick: PropTypes.func.isRequired,
  onCompoByInciButtonClick: PropTypes.func.isRequired,
  onUpdateInciButtonClick: PropTypes.func.isRequired,
  onUpdateMaxQtyButtonClick: PropTypes.func.isRequired,
  onLatinAmericaAnastasiaCompoClick: PropTypes.func.isRequired,
  buttonsDisabled: PropTypes.bool.isRequired,
  isUpdatingMaxQty: PropTypes.bool.isRequired,
  isLoadingShadeInciModal: PropTypes.bool.isRequired,
  isLoadingShadeRmModal: PropTypes.bool.isRequired,
};

export default ShadeTab;
