import { takeLatest, takeEvery, all, put, select, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import * as rawMatProportionTypes from './rawMatProportionTypes';
import * as rawMatTypes from '../rawMat/rawMatTypes';
import * as rawMatProportionActions from './rawMatProportionActions';
import { applicationActions } from '../application';
import { rawMatProportionListSchema, rawMatProportionSchema } from '../schema';
import { modalSelectors, modalTypes, modalActions } from '../modal';
import { rawMatActions } from '../rawMat';
import rawMatProportionApi from '../../api/rawMatProportionApi';
import { callApiSaga } from '../sagaUtils';

export default function* root() {
  yield all([
    takeLatest(rawMatProportionTypes.DELETE_RAWMAT_PROPORTION.REQUEST, deleteRawMatProportionSaga),
    takeLatest(rawMatProportionTypes.ADD_RAWMAT_PROPORTIONS_FROM_RAWMAT.REQUEST, addRawMatProportionsFromRawMatSaga),
    takeLatest(rawMatProportionTypes.SAVE_RAWMAT_PROPORTIONS.REQUEST, saveRawMatProportionsSaga),
    takeEvery(rawMatProportionTypes.FETCH_RAWMAT_PROPORTIONS.REQUEST, fetchRawMatProportionsSaga),
  ]);
}

export function* fetchRawMatProportionsSaga({ payload, type, meta = {} }) {
  yield put(applicationActions.setLoading(type));
  try {
    const rawMatProportions = yield call(rawMatProportionApi.fetchRawMatProportions, payload);
    const schema = Array.isArray(rawMatProportions) ? rawMatProportionListSchema : rawMatProportionSchema;
    const normalizedData = normalize(rawMatProportions, schema);
    yield put(rawMatProportionActions.fetchRawMatProportions.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(rawMatProportionActions.fetchRawMatProportions.failure(error));
    }
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}

export function* saveRawMatProportionsSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const updateTransaction = yield call(rawMatProportionApi.saveRawMatProportion, payload);
    const { Data, idsToDelete: deletedIds = [] } = updateTransaction;
    const normalizedData = normalize(Data, rawMatProportionSchema.rawMatProportionListSchema);
    yield put(
      rawMatProportionActions.saveRawMatProportions.success(normalizedData, {
        deletedIds,
      }),
    );
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(rawMatProportionActions.saveRawMatProportions.failure(error));
    }
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}

function* addRawMatProportionsFromRawMatSaga({ payload }) {
  const { rawMatId } = payload;
  yield put(applicationActions.setLoading(rawMatTypes.SAVE_RAWMAT_PROPORTION.REQUEST));

  yield callApiSaga(
    rawMatProportionActions.addRawMatProportionsFromRawMat,
    rawMatProportionApi.addRawMatProportionsFromRawMat,
    payload,
  );
  const modalType = yield select(state => modalSelectors.getModalType(state));
  if (modalType === modalTypes.RAWMAT_PROPORTION) {
    yield put(modalActions.hideModal());
  }
  if (rawMatId) {
    yield put(
      rawMatProportionActions.fetchRawMatProportions.request({
        rawMatId,
        include: {
          OptIncludeSubstance: true,
          OptIncludeFunctions: true,
          OptIncludeSubstanceProportions: false
        },
      }),
    );
    yield put(rawMatActions.listAllowedNewSubstanceInComposition.request(rawMatId));
  }

  yield put(applicationActions.unsetLoading(rawMatTypes.SAVE_RAWMAT_PROPORTION.REQUEST));
}

function* deleteRawMatProportionSaga(action) {
  const { rawMatId, rawMatProportionId } = action.payload;
  yield put(applicationActions.setLoading(rawMatProportionTypes.DELETE_RAWMAT_PROPORTION.REQUEST));
  yield callApiSaga(
    rawMatProportionActions.deleteRawMatProportion,
    rawMatProportionApi.deleteRawMatProportion,
    rawMatProportionId,
  );
  if (rawMatId) {
    yield put(
      rawMatProportionActions.fetchRawMatProportions.request({
        rawMatId,
        include: {
          OptIncludeSubstance: true,
          OptIncludeFunctions: true,
        },
      }),
    );
  }

  yield put(applicationActions.unsetLoading(rawMatProportionTypes.DELETE_RAWMAT_PROPORTION.REQUEST));
}
