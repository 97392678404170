import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import SelectionGrid from '../_Common/Grids/SelectionGrid';

const SubstanceList = props => {
  const {
    substanceList,
    requestingSubstanceList,
    onSubstanceSelected,
    onAddSubstance,
    onRefreshList,
    selectedId,
  } = props;

  const columns = [
    {
      headerName: I18n.t('substance.casNr'),
      width: 30,
      field: 'CasNr',
      // suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('substance.inciName'),
      width: 70,
      field: 'InciName',
      sort: 'asc',
    },
  ];

  return (
    <SelectionGrid
      data={substanceList}
      onSelected={onSubstanceSelected}
      onRefresh={onRefreshList}
      onAdd={onAddSubstance}
//      isLoading={requestingSubstanceList}
      showLoadingIcon={requestingSubstanceList}
      selectedId={selectedId}
      columns={columns}
      suppressRowClickSelection
      keyName="SubstanceId"
      showAddButton = {false}
    />
  );
};

SubstanceList.defaultProps = {
  selectedId: '',
};

SubstanceList.propTypes = {
  substanceList: PropTypes.arrayOf(
    PropTypes.shape({
      SubstanceId: PropTypes.number.isRequired,
      Code: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSubstanceSelected: PropTypes.func.isRequired,
  onAddSubstance: PropTypes.func.isRequired,
  onRefreshList: PropTypes.func.isRequired,
  requestingSubstanceList: PropTypes.bool.isRequired,
  selectedId: PropTypes.string,
};

export default SubstanceList;
