import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Grid, Form, Row } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { compose } from 'recompose';
import formNames from '../../../../constants/formNames';
import { InputField, DateField, CheckBoxField } from '../../../_Common/Forms';
// import { shadeFormSelectors } from '../../../../redux/form/shadeForm';
// import { shadeActions } from '../../../../redux/shade';
import { notificationFormSelectors } from '../../../../redux/form/notificationForm';
import { notificationActions } from '../../../../redux/notification';
import { maxLength16, maxLength64, maxLength128 } from '../../../../utils/fieldValidation';
import { referentialSelectors } from '../../../../redux/referentials';
import { companySelectors } from '../../../../redux/company';
import SelectField from "../../Forms/SelectField";
import regionCode from "../../../../constants/regionCode";

const formName = formNames.notification;

const NotificationForm = props => {
  const { onSubmit, handleSubmit } = props;
  const { regions, countries, companyList, selectedRegion, change } = props;
  const finalCountryList = selectedRegion ? countries.filter(elem => elem.RegionCode === selectedRegion) : countries;
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid fluid>
        <Row>
          <Col sm={12}>
          <Col sm={6}>
            <Field
              name="RegionCode"
              label={I18n.t('notification.region')}
              component={SelectField}
              options={regions}
              labelField="Value"
              valueField="Key"
              matchPos="start"
              onValueChange={() => change('CountryAlpha2', null)}
            />
          </Col>
          <Col sm={6}>
            <Field
              name="CountryCode"
              label={I18n.t('notification.country')}
              component={SelectField}
              options={finalCountryList}
              labelField="Value"
              valueField="Key"
              matchPos="start"
              disabled={!selectedRegion || selectedRegion !== regionCode.ASEAN}
            />
          </Col>
          <Col sm={6}>
            <Field
              name="ResponsibleCompanyId"
              label={I18n.t('product.responsiblePerson')}
              component={SelectField}
              options={companyList}
              labelField="ShortName"
              valueField="CompanyId"
              disabled={!selectedRegion}
            />
          </Col>
          {/* <Field
              name="ShadeCode"
              label={`${I18n.t('shade.ShadeCode')} *`}
              component={InputField}
              validate={[requiredField, maxLength48]}
            /> */}
          </Col>
          {/* <Col sm={12}>
            <Field
              name="ShadeName"
              label={`${I18n.t('shade.ShadeName')}`}
              component={InputField}
              validate={maxLength128}
            />
          </Col> */}
          <Col sm={4}>
            <Field
              name="IsNotified"
              label={I18n.t('product.isNotified')}
              component={CheckBoxField}
              formAlignment={false}
            />
          </Col>
          <Col sm={4}>
            <Field
              name="NotifiedOn"
              label={`${I18n.t('notification.notifiedOn')}`}
              component={DateField}
              validate={maxLength128}
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                },
              }}
            />
          </Col>
          <Col sm={4}>
            <Field
              name="ExpiresOn"
              label={`${I18n.t('notification.expiresOn')}`}
              component={DateField}
              validate={maxLength128}
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                }
              }}
            />
          </Col>
          {
          <>
          <Col sm={12}>
            <Field
              name="NotificationCode"
              label={`${I18n.t('notification.notificationCode')}`}
              component={InputField}
              validate={maxLength16}
            />
          </Col>
          <Col sm={12}>
            <Field
              name="ReserveCode1"
              label={`${I18n.t('notification.reserveCode1')}`}
              component={InputField}
              validate={maxLength64}
            />
          </Col>
          <Col sm={12}>
            <Field
              name="ReserveCode2"
              label={`${I18n.t('notification.reserveCode2')}`}
              component={InputField}
              validate={maxLength64}
            />
          </Col>
          </>
          }
        </Row>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state, { notificationId }) => {
  const getFormInitialValues = notificationFormSelectors.makeGetInitialValues(notificationId);
  const selector = formValueSelector(formNames.notification);
  const selectedRegion = selector(state, 'RegionCode');
  return {
    initialValues: getFormInitialValues(state),
    regions: referentialSelectors.getRegions(state),
    countries: referentialSelectors.getCountryList(state),
    companyList: companySelectors.getCompanyList(state),
    selectedRegion,
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onSubmit: data => {
    dispatch(notificationActions.saveNotification.request({ ...data, productId }));
  },
});

NotificationForm.defaultProps = {
  initialValues: {
    notificationId: -1
  },
};

NotificationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(NotificationForm);
