import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import CompanySubscriptionsList from './CompanySubscriptionsList';

const CompanySubscriptionsTab = props => {
  const { ...otherProps } = props;
  return (
    <div>
      <CompanySubscriptionsList {...otherProps} />
    </div>
  );
};

export default CompanySubscriptionsTab;
