import React from 'react';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-redux-i18n';
import { ButtonToolbar, Button } from 'react-bootstrap';
import { SelectionGrid, DateCellRenderer, TooltipRenderer } from '../../_Common/Grids';

const ActionButtonsRenderer = props => {
  const { onCompoClick, onEditClick, onDeleteClick, data, buttonsDisabledByValidedComposition } = props;

  const handleButtonClick = fn => () => fn(data);

  return (
    <div>
      <ButtonToolbar>
        <Button
          bsStyle="info"
          bsSize="xsmall"
          type="button"
          onClick={handleButtonClick(onEditClick)}
          disabled={buttonsDisabledByValidedComposition}
        >
          <i className="fa fa-pen" />
          {/* &nbsp;
          <Translate value="general.edit" /> */}
        </Button>
        <Button bsSize="xsmall" type="button" onClick={handleButtonClick(onCompoClick)}>
          <i className="fa fa-list" aria-hidden="true" />
          &nbsp;
          <Translate value="shade.Compo" />
        </Button>
        <Button
          bsSize="xsmall"
          bsStyle="danger"
          type="button"
          onClick={handleButtonClick(onDeleteClick)}
          disabled={buttonsDisabledByValidedComposition}
        >
          <i className="fa fa-trash" aria-hidden="true" />
          &nbsp;
          <Translate value="general.delete" />
        </Button>
      </ButtonToolbar>
    </div>
  );
};

ActionButtonsRenderer.propTypes = {
  data: PropTypes.object.isRequired, //eslint-disable-line
  onEditClick: PropTypes.func.isRequired,
  onCompoClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

const ShadeList = props => {
  const {
    shadeList,
    isLoading,
    fetchShadeList,
    onAddButtonClick,
    onEditButtonClick,
    onCompoButtonClick,
    onDeleteButtonClick,
    buttonsDisabledByValidedComposition,
  } = props;

  const columns = [
    {
      headerName: I18n.t('shade.ShadeCode'),
      field: 'ShadeCode',
      width: 120,
    },
    {
      headerName: I18n.t('shade.ShadeName'),
      field: 'ShadeName',
      width: 100,
    },
    {
      headerName: I18n.t('shade.InciListNr'),
      sort: 'asc',
      field: 'InciListNr',
      width: 50,
    },
    {
      headerName: I18n.t('shade.NotificationCode'),
      field: 'NotificationCode',
      width: 70,
    },
    {
      headerName: I18n.t('shade.NotifiedOn'),
      field: 'NotifiedOn',
      cellRenderer: DateCellRenderer,
      width: 70,
    },
    {
      headerName: I18n.t('shade.InciList'),
      field: 'InciText',
      cellRendererFramework: TooltipRenderer,
      widthWidth: 250,
    },
    {
      headerName: I18n.t('general.actions'),
      width: 100,
      cellRendererFramework: ActionButtonsRenderer,
      suppressSizeToFit: true,
      cellRendererParams: {
        onEditClick: onEditButtonClick,
        onCompoClick: onCompoButtonClick,
        onDeleteClick: onDeleteButtonClick,
        buttonsDisabledByValidedComposition,
      },
    },
  ];

  return (
    <SelectionGrid
      data={shadeList}
      keyName="ShadeId"
      columns={columns}
      onAdd={onAddButtonClick}
      isLoading={isLoading}
      onRefresh={fetchShadeList}
      addDisabled={buttonsDisabledByValidedComposition}
    />
  );
};

ShadeList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fetchShadeList: PropTypes.func.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onCompoButtonClick: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
  shadeList: PropTypes.array.isRequired, //eslint-disable-line
};

export default ShadeList;
