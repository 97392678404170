import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import SubstanceList from './SubstanceList';
import { substanceActions, substanceTypes, substanceSelectors } from '../../redux/substance';
import { productProportionActions, productProportionTypes } from '../../redux/productProportion';
import { applicationSelectors } from '../../redux/application';
import { rawMatProportionActions } from '../../redux/rawMatProportion';

class SubstancesPageContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired, //eslint-disable-line
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  };

  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const { params } = this.props;
    const selectedId = params ? params.id : undefined;
    return <SubstanceList selectedId={selectedId} {...this.props} />;
  }
}

const mapStateToProps = () => {
  const getSubstancesForProduct = substanceSelectors.makeGetInciSubstancesForProduct();
  const getSubstanceForRawMat = substanceSelectors.makeGetInciSubstancesForRawMat();

  return (state, ownProps) => {
    const {
      location: { query },
    } = ownProps;
    const getSubstanceList = query.productId
      ? getSubstancesForProduct(Number(query.productId))
      : query.rawMatId
        ? getSubstanceForRawMat(Number(query.rawMatId))
        : substanceSelectors.getSubstanceList;
    return {
      substanceList: getSubstanceList(state),
      requestingSubstanceList: applicationSelectors.isLoading(
        state,
        substanceTypes.SUBSTANCE_LIST.REQUEST,
        productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.REQUEST,
      ),
    };
  };
};

const mapDispatchToProps = (dispatch, { location }) => {
  const { query } = location;
  return {
    onLoad: () =>
      (query.productId
        ? dispatch(productProportionActions.listProductProportionsForProduct.request(Number(query.productId)))
        : query.rawMatId
          ? dispatch(
            rawMatProportionActions.fetchRawMatProportions.request({
              rawMatId: Number(query.rawMatId),
              include: {
                OptIncludeSubstance: true,
                OptIncludeFunctions: true,
              },
            }),
          )
          : dispatch(substanceActions.listSubstances.request())),
    onSubstanceSelected: id => dispatch(push({ ...location, pathname: `/substances/${id}` })),
    //onAddSubstance: () => dispatch(push({ query: { show_details: true }, pathname: '/substances/-1' })),
    onRefreshList: () => dispatch(substanceActions.listSubstances.request()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubstancesPageContainer);
