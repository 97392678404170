import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';


class LinkCellRenderer extends React.Component {
  render() {
    var url = "";
    if(this.props.linkFunc !== undefined)
    {
      url = this.props.linkFunc(this.props.data);
    }else{ url = get(this.props.data, this.props.linkKey); }

    if(this.props.downloadable){
      return (
        <a href={url} download>
          {get(this.props.data, this.props.textKey)}
      </a>
        );
    } else {
      return (
        <a href={url} target={this.props.target} >
          {get(this.props.data, this.props.textKey)}
    </a>
      );
    }
  }
}

LinkCellRenderer.defaultProps = {
  linkKey: 'FileLink',
  textKey: 'FileName',
  linkFunc: undefined,
  target: '_self',
  c: true
};

LinkCellRenderer.propTypes = {
  data: PropTypes.object.isRequired, //eslint-disable-line
  linkKey: PropTypes.string,
  linkFunc: PropTypes.func,
  textKey: PropTypes.string,
  downloadable: PropTypes.bool,
  target: PropTypes.string
};

export default LinkCellRenderer;
