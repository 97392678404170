import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { SelectionGrid } from '../../_Common/Grids';
import { isUndefined } from '../../../utils/utils';

const CosAnnexeList = props => {
  const { cosAnnexeList, substanceCosAnnexeList, isLoading, fetchCosAnnexeList, cosAnnexeTypes } = props;

  const getCosAnnexeTypeByCode = R.compose(
    R.defaultTo({}),
    R.flip(R.useWith(R.find, [R.propEq('Code'), R.identity]))(cosAnnexeTypes),
  );

  const getCosAnnexeByCode = R.compose(
    R.defaultTo({}),
    R.flip(R.useWith(R.find, [R.propEq('Code'), R.identity]))(cosAnnexeList),
  );

  const getCosAnnexeFromRow = R.compose(getCosAnnexeByCode, R.pathOr({}, ['data', 'CosAnnexeCode']));

  const getValueFromColDef = R.path(['colDef', 'field']);

  const getCosAnnexeValue = R.converge(R.propOr(''), [getValueFromColDef, getCosAnnexeFromRow]);

  const getCosAnnexeTypeDescription = R.compose(R.propOr('', 'Description'), getCosAnnexeTypeByCode, getCosAnnexeValue);

  const formatMaxUse = R.compose(
    R.join(' '),
    R.filter(R.complement(R.isNil)),
    R.props(['MaxUse', 'MaxUseUom']),
    getCosAnnexeFromRow,
  );

  const columns = [
    {
      headerName: I18n.t('cosAnnexe.Code'),
      field: 'Code',
      width: 75,
      valueGetter: getCosAnnexeValue,
    },
    {
      headerName: I18n.t('general.region'),
      field: 'CosAnnexeRegionCode',
      width: 75,
    },
    {
      headerName: I18n.t('general.country'),
      field: 'CosAnnexeCountryAlpha2',
      width: 75,
    },
    {
      headerName: I18n.t('cosAnnexe.MaxUse'),
      width: 100,
      valueGetter: formatMaxUse,
      field: 'MaxUse',
    },
    {
      headerName: I18n.t('cosAnnexe.CalculationCondition'),
      valueGetter: getCosAnnexeValue,
      field: 'CalculationCondition',
    },
    {
      headerName: I18n.t('cosAnnexe.UseConditionWording'),
      valueGetter: getCosAnnexeValue,
      field: 'UseConditionWording',
    }, // {
    {
      headerName: I18n.t('cosAnnexe.Warning'),
      valueGetter: getCosAnnexeValue,
      field: 'Warning',
    },
    {
      headerName: I18n.t('cosAnnexe.SccsOpinions'),
      valueGetter: getCosAnnexeValue,
      field: 'SccpOpinions',
    },
    {
      headerName: I18n.t('cosAnnexe.OtherRestrictions'),
      valueGetter: getCosAnnexeValue,
      field: 'OtherRestrictions',
    },
    {
      headerName: I18n.t('cosAnnexe.OtherRemarks'),
      valueGetter: getCosAnnexeValue,
      field: 'OtherRemarks',
    },
    {
      headerName: I18n.t('cosAnnexe.UsedIn'),
      valueGetter: getCosAnnexeTypeDescription,
      field: 'UsedIn',
    },
    {
      headerName: I18n.t('cosAnnexe.NotUsedIn'),
      valueGetter: getCosAnnexeTypeDescription,
      field: 'NotUsedIn',
    },
  ];

  substanceCosAnnexeList.map(elem => {
    const cosAnnexe = cosAnnexeList.find(f => f.CosAnnexeId === elem.CosAnnexe);
    if(!isUndefined(cosAnnexe)) {        
      elem.CosAnnexeRegionCode = cosAnnexe.RegionCode;
      elem.CosAnnexeCountryAlpha2 = cosAnnexe.CountryAlpha2;
    }
  });

  return (
    <SelectionGrid
      key={Math.random()}
      data={substanceCosAnnexeList}
      keyName="SubstanceCosAnnexeId"
      columns={columns}
      showAddButton={false}
      isLoading={isLoading}
      onRefresh={fetchCosAnnexeList}
    />
  );
};

CosAnnexeList.defaultProps = {
  CosAnnexeList: [],
  substanceCosAnnexeList: [],
};

CosAnnexeList.propTypes = {
  cosAnnexeList: PropTypes.array.isRequired, //eslint-disable-line
  substanceCosAnnexeList: PropTypes.array.isRequired, //eslint-disable-line
  cosAnnexeTypes: PropTypes.array.isRequired, //eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  fetchCosAnnexeList: PropTypes.func.isRequired,
};

export default CosAnnexeList;
