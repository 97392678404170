import PropTypes from 'prop-types';
import React from 'react';
import { Col, Tabs, Tab } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import { css } from 'glamor';
import CompanyForm from './CompanyForm';
import CompanyContactFormSection from './CompanyContactFormSection';
//import CompanyDistributors from './CompanyDistributors';
//import CompanyDistributionChannels from './CompanyDistributionChannels';
import CompanyOrders from './CompanyOrders';
import CompanyProducts from './CompanyProducts';
import CompanySubscriptions from './CompanySubscriptions';
//import CompanyNotifications from './CompanyNotifications';
import CompanyDocuments from './CompanyDocuments';
import CompanyRawMats from './CompanyRawMats';

const styles = {
  detailsContainer: css({
    '& form': {
      height: '100%',
    },
    '& .tab-pane': {
      paddingTop: '15px',
    },
  }),
  buttonPanel: css({
    textAlign: 'center',
  }),
  closeButton: css({
    // marginTop: '25px',
  }),
};

const CompanyDetails = props => {
  const { companyId, languageList } = props;
  return (
    <div className={styles.detailsContainer}>
      <CompanyForm {...props} noDrag={true}/>
      <Col sm={12}>
        <Tabs id="CompanyTabs">
          <Tab eventKey={2} title={I18n.t('company.contacts')}>
            <div className={styles.tabs}>
              <CompanyContactFormSection languageList={languageList} />
            </div>
          </Tab>
          <Tab eventKey={3} title={I18n.t('company.orders')}>
            <div>
              <CompanyOrders companyId={companyId} />
            </div>
          </Tab>
          <Tab eventKey={4} title={I18n.t('company.products')}>
            <div>
              <CompanyProducts companyId={companyId} {...props} />
            </div>
          </Tab>
          <Tab eventKey={5} title={I18n.t('company.subscriptions')}>
            <div>
              <CompanySubscriptions companyId={companyId} {...props} />
            </div>
          </Tab>
          {/* <Tab eventKey={9} title={I18n.t('company.notifications')}>
            <div>
              <CompanyNotifications companyId={companyId} {...props} />
            </div>
          </Tab> */}
          {/* <Tab eventKey={5} title={I18n.t('company.distributors')}>
            <div>
              <CompanyDistributors companyId={companyId} />
            </div>
          </Tab> */}
          {/* <Tab eventKey={6} title={I18n.t('company.distributionChannels')}>
            <div>
              <CompanyDistributionChannels companyId={companyId} />
            </div>
          </Tab> */}
          <Tab eventKey={7} title={I18n.t('company.rawMats')}>
            <div>
              <CompanyRawMats companyId={companyId} {...props} />
            </div>
          </Tab>
          <Tab eventKey={8} title={I18n.t('company.documents')}>
            <CompanyDocuments companyId={companyId} {...props} />
          </Tab>
        </Tabs>
      </Col>
    </div>
  );
};

CompanyDetails.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default CompanyDetails;
