import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { productActions, productTypes, productSelectors } from '../../../redux/product';
import { productCategoryActions, productCategorySelectors } from '../../../redux/productCategory';
import { productClpActions } from '../../../redux/productClp';
import { applicationSelectors } from '../../../redux/application';
import MosCalculationTab from './MosCalculationTab';

class MosCalculationContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    productId: PropTypes.number.isRequired,
    currentProductMosCalculationList: PropTypes.array.isRequired, //eslint-disable-line
  };

  componentDidMount() {
    if (this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId && this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <MosCalculationTab {...this.props} />;
  }
}

const mapStateToProps = (state,  { productId }) => {
  const { currentProductMosCalculationList } = state.product;
  return {
    currentProductMosCalculationList,
    // currentProductCategories: productSelectors.getCurrentProductCategories(state),
    currentProductCategories: productCategorySelectors.getProductCategoryByProductId(state, productId),
    isLoadingMosCalculationTab: applicationSelectors.isLoading(
      state,
      productTypes.PRODUCT_MOS_CALCULATION_LIST.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onLoad: () => {
    dispatch(productActions.listProductMosCalculationList.request({ productId }));
    dispatch(productCategoryActions.listProductCategories.request(productId));
  },
  onRowDoubleClicked: ({ SubstanceId }) => dispatch(push(`/substances/${SubstanceId}`)),
  onRefresh: () => {
    dispatch(productActions.listProductMosCalculationList.request({ productId }))
    dispatch(productClpActions.fetchProductClpForProduct.request(productId))
  },
  onCalculateMoSClicked: (data) => {
    dispatch(productActions.listProductMosCalculationList.request({ productId, productCategory: data }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MosCalculationContainer);
